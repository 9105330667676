<template>
    <v-row class="mt-1 mx-3">
      <v-row style="height: auto; display: flex">
        <v-col>
          <v-card-title class="card-h4-title">OUTLET MAP
          </v-card-title>
        </v-col>
        <v-col class="card-body">
          <v-btn class="red-light-text" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
        </v-col>

      </v-row>

      <v-divider class="my-3"></v-divider>
        <v-col cols="3">
            <v-select
                v-model="outlet.provinces"
                :items="provinces"
                :loading="mProvinceLoading"
                clearable
                label="Province"
                multiple
                placeholder="Select province(s)"
                variant="outlined"
                @update:modelValue="getAllDistrictAccProv()"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
                v-model="outlet.districts"
                :items="districts"
                :loading="mDistrictLoading"
                clearable
                label="District"
                multiple
                placeholder="Select district(s)"
                variant="outlined"
                @update:modelValue="getAllDsd"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
                v-model="outlet.dsdList"
                :items="dsdList"
                :loading="mDSDLoading"
                clearable
                label="DSD"
                multiple
                placeholder="Select DSD(s)"
                variant="outlined"
                @update:modelValue="getAllGnd"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
                v-model="outlet.gndList"
                :items="gndList"
                :loading="mGndLoading"
                clearable
                label="GND"
                multiple
                placeholder="Select GND(s)"
                variant="outlined"
            ></v-select>
        </v-col>
    </v-row>
    <v-row class="mx-3">
        <v-col cols="12">
          <Map ref="mapElement" :center="mapCenter" :zoom="12" class="d-flex map-styles">
          <Marker
                
                v-for="outlet in filteredOutlets"
                :key="outlet.id"
                :icon="{
                    url: require('@/assets/ICONS/maker.png'),
                    scaledSize: { width: 30, height: 25 },
                }"
                :position="{ lat: parseFloat(outlet.latitude), lng: parseFloat(outlet.longitude) }"
                :title="outlet.outlet_name"
              >
          </Marker>
          </Map>
        </v-col>
    </v-row>
</template>
    
<script>

import { Map, Marker,  } from '@fawmi/vue-google-maps';
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
    components: {
        Map,
        Marker,
        
    },
    name: 'OutletMapDashboardComponentFour',

  data(){

      return{
        provinces: [],
        districts: [],
        dsdList: [],
        gndList: [],

        outlet: {
          provinces: [],
          districts: [],
          dsdList: [],
          gndList: [],
        },


        mapCenter: { lat: 6.8738188, lng: 79.9931036 }, // Default map center
      outlets: [], 
      }
  },

  mounted() {
    this.getAllProvinces();
    this.getOutletLocation();
  },

  computed: {
    // filteredOutlets() {
    //   return this.outlets.filter(outlet => outlet && outlet.id && outlet.latitude && outlet.longitude);
      
    // }
    filteredOutlets() {
      console.log('Selected filters:', this.outlet);
      console.log('All outlets:', this.outlets);

      return this.outlets.filter((outlet) => {
        return (
            (!this.outlet.provinces.length || this.outlet.provinces.includes(outlet.province)) &&
            (!this.outlet.districts.length || this.outlet.districts.includes(outlet.district)) &&
            (!this.outlet.dsdList.length || this.outlet.dsdList.includes(outlet.dsd)) &&
            (!this.outlet.gndList.length || this.outlet.gndList.includes(outlet.gnd))
        );
      });
    },


  },
 
  methods: {

    exportPDF() {

      this.$nextTick(() => {
        const mapElement = this.$refs.mapElement?.$el || this.$refs.mapElement;

        if (mapElement instanceof HTMLElement) {
          html2canvas(mapElement, {
            useCORS: true, // Use CORS to allow cross-origin tiles to be captured
            logging: true,
            scale: 2 // Increase the scale to get better resolution
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
              orientation: 'landscape', // You can adjust this based on your map size
              unit: 'pt',
              format: [canvas.width, canvas.height] // Use the exact size of the canvas for PDF format
            });

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

            // Save the PDF
            pdf.save('outlet_map.pdf');
          }).catch((error) => {
            console.error('Error capturing the map:', error);
          });
        } else {
          console.error('Map element is not attached or is not a valid node.');
        }
      });
    },

    getOutletLocation() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 

      axios.post('/api/getOutletLocationDetails', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          this.outlets = response.data.data;
          this.setMapCenter();
          console.log('data',this.outlets);
        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
      },
      setMapCenter() {
            if (this.outlets.length > 0) {
              // Center the map based on the first outlet location
              const firstOutlet = this.outlets[0];
              this.mapCenter = {
                lat: parseFloat(firstOutlet.latitude),
                lng: parseFloat(firstOutlet.longitude),
              };
            }
          },
  getAllProvinces() {
    this.districts = [];
    this.dsdList = [];
    this.gndList = [];
    this.mProvinceLoading = true;
    // let laravel = JSON.parse(window.Laravel);
    const header = {
      // 'X-CSRF-TOKEN': laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'multipart/form-data',
    };

    axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
        .then(response => {
          if (response.data.http_status === 'success') {
            this.provinces = response.data.data;
            this.mProvinceLoading = false;
          }
        })
        .catch(() => {
          this.mProvinceLoading = false;
        });
  },

    getAllDistrictAccProv(provinceArray) {
      console.log('Provinces selected:', provinceArray);
      this.districts = [];
      this.mDistrictLoading = true;

      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', values: provinceArray }, headers)
          .then((response) => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch((error) => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);
          });
    },


    getAllDsd(districtArray) {
      console.log('Districts selected:', districtArray);
      this.dsdList = [];
      this.mDSDLoading = true;

      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios
          .post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', values: districtArray }, headers)
          .then((response) => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch((error) => {
            this.mDSDLoading = false;
            console.error('Error fetching DSDs:', error);
          });
    },

    getAllGnd(dsdArray) {
      console.log('DSDs selected:', dsdArray);
      this.gndList = [];
      this.mGndLoading = true;

      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios
          .post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', values: dsdArray }, headers)
          .then((response) => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGndLoading = false;
            }
          })
          .catch((error) => {
            this.mGndLoading = false;
            console.error('Error fetching GNDs:', error);
          });
    },

  },
  watch: {
    "outlet.provinces"(newValues) {
      if (!newValues.length) {
        this.outlet.districts = [];
        this.outlet.dsdList = [];
        this.outlet.gndList = [];
      } else {
        this.getAllDistrictAccProv(newValues);
      }
    },
    "outlet.districts"(newValues) {
      if (!newValues.length) {
        this.outlet.dsdList = [];
        this.outlet.gndList = [];
      } else {
        this.getAllDsd(newValues);
      }
    },
    "outlet.dsdList"(newValues) {
      if (!newValues.length) {
        this.outlet.gndList = [];
      } else {
        this.getAllGnd(newValues);
      }
    },
    // 'outlet.province'(newValue) {
    //   this.getAllDistrictAccProv(newValue);
    // },
    // 'outlet.district'(newValue) {
    //   this.getAllDsd(newValue);
    // },
    // 'outlet.dsd'(newValue) {
    //   this.getAllGnd(newValue);
    // }
  },
  
};
</script>
    
<style scoped>
.v-card-title {
    font-weight: bold;
}
.map-styles {
  width: 100%;
  height: 400px;

}


.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}


.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;

}

.card-body{
  display: flex;
  justify-content: end;
  justify-items: end;
  margin-right: 5%;
}


.red-text {
  color: #b00d0d;
}
</style>
    