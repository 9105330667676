import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            sharedData: null,
            sharedDataRoutes: null,
            sharedDataCollection: null,
            sharedDataOutlet: null,
            organizationSettings: {},
            agentListDetails:[],

            dashboard: {
                totalSales: 0,
                SalesPresentage: 0,
                totalCollection: 0,
                CollectionPresentage: 0,
                OutletPresantage: 0,
                totalOutlet:0,
                totalStock: 0,
                totalProfit:0,
                StockPresantage: 0,
                profitPresantage: 0,
                totalLeasds: 0,
                leadsPresantage: 0
              },

              

        };
    },
    mutations: {
        setSharedData(state, data) {
            console.log('Setting shared data:', data);
            state.sharedData = data;
        },
        setSharedDataRoutes(state, data) {
            console.log('Setting shared data:', data);
            state.sharedDataRoutes = data;
        },
        setSharedDataCollection(state, data) {
            console.log('Setting shared data:', data);
            state.sharedDataCollection = data;
        },
        setSharedDataOutlet(state, data) {
            console.log('Setting shared data:', data);
            state.sharedDataOutlet = data;
        },
        SET_ORGANIZATION_SETTINGS(state, settings) {
            state.organizationSettings = settings;
            localStorage.setItem('organizationSettings', JSON.stringify(settings));
        },

        setDashboardDetails(state, payload) {
            state.dashboard = payload;
        },
        setAgentList(state, payload) {
            state.agentListDetails = payload;
        }

        

        
    },
    actions: {
        updateSharedData({ commit }, data) {
            console.log('Updating shared data:', data)
            commit('setSharedData', data);
        },
        updateSharedDataRoutes({ commit }, data) {
            console.log('Updating shared data:', data)
            commit('setSharedDataRoutes', data);
        },
        updateSharedDataCollection({ commit }, data) {
            console.log('Updating shared data:', data)
            commit('setSharedDataCollection', data);
        },
        updateSharedDataOutlet({ commit }, data) {
            console.log('Updating shared data:', data)
            commit('setSharedDataOutlet', data);
        },
        updateSharedDataOrganization({ commit, state }, updatedSetting) {
            const newSettings = { ...state.organizationSettings, ...updatedSetting };
            commit('SET_ORGANIZATION_SETTINGS', newSettings);
        },
        updateDashboardDetails({ commit }, dashboardData) {
            commit('setDashboardDetails', dashboardData);
        },
        updateAgentList({ commit }, agentList) {
            commit('setAgentList', agentList);
            // console.log('damoidioa',agentList);
        }

    },
    getters: {
        sharedData: (state) => state.sharedData,
        sharedDataRoutes: (state) => state.sharedDataRoutes,
        sharedDataCollection: (state) => state.sharedDataCollection,
        sharedDataOutlet: (state) => state.sharedDataOutlet,
        organizationSettings: state => state.organizationSettings,
        getDashboardDetails: (state) => state.dashboard,
        getagentListDetails: (state) => state.agentListDetails
    },
});