<template>
  <v-card flat style="height: 100%; width: 100%;">
    <v-row class="px-6">
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">SETUP FUNCTIONALITIES</h3>
        <h5 class="light-gray-text">Select which functions should be enabled according to the organization being
          processed.</h5>
      </v-col>
      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style align-end justify-end">
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-card style="padding: 20px; height: 100%; width: 100%; margin-left: 10px">
      <h5 class="card-h4-title px-3 mt-2 mb-4 ml-2">SETUP FUNCTIONALITIES</h5>
      <v-divider></v-divider>

      <v-row style="padding-left: 10px; padding-right: 10px; max-height: 65vh;">
        <v-col class="d-flex justify-center" cols="12" md="6">
          <v-card class="text-center" style="padding: 8px; height: 60vh; margin-top: 20px">
            <v-row v-for="(item, index) in items" :key="item.text" class="align-center no-gutters" style="height: 6vh;">
              <v-col class="d-flex align-center" cols="auto">
                <v-switch
                    v-model="item.isOn"
                    :color="item.isOn ? 'red' : 'grey'"
                    class="ma-1"
                    style="width: 80px;"
                    @change="handleSwitchChange(item)"
                ></v-switch>
              </v-col>
              <v-col class="d-flex mb-6" cols="auto">
                <v-card-text :class="{ 'red-text': item.isOn, 'grey-text': !item.isOn }" class="ma-1">{{
                    item.text
                  }}
                </v-card-text>
              </v-col>
              <v-divider v-if="index < items.length - 1"></v-divider>
            </v-row>
          </v-card>
        </v-col>

        <v-col class="d-flex justify-center" cols="12" md="6">
          <v-img
              alt="Description of the image"
              class="my-image"
              src="@/assets/Organization Management/Dropdown menu-rafiki.png"
              style="height: 50%; width: 50%; margin-top: 40px;"
          ></v-img>
        </v-col>
      </v-row>

      <v-divider class="divider-two-style mt-4"></v-divider>
      <v-row class="mt-2 mr-8">
        <v-spacer></v-spacer>
        <!--        <v-btn :disabled="loading" class="red-background-text mr-3" variant="outlined" @click="complete">COMPLETE-->
        <!--        </v-btn>-->
        <v-btn class="gray-background-text" variant="outlined" @click="toggleAll">{{ toggleAllText }}</v-btn>
      </v-row>
    </v-card>
  </v-card>
</template>


<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'OrganizationManagement',

  data() {
    return {
      selectAll: false, // Initial state flag
      items: [
        {text: 'Dashboard', isOn: true},
        {text: 'Product Management', isOn: true},
        {text: 'Leads Management', isOn: true},
        {text: 'User Management', isOn: true},
        {text: 'Sales Management', isOn: true},
        {text: 'Collection Management', isOn: true},
        {text: 'Tracking Management', isOn: true},
        {text: 'Route Management', isOn: true},
        {text: 'Outlet Management', isOn: true}
      ]
    };
  },

  computed: {
    toggleAllText() {
      return this.selectAll ? 'Clear All' : 'Select All';
    },

    ...mapState(['organizationSettings']),
  },

  watch: {
    // Watch for changes in items and check the selection state
    items: {
      handler() {
        this.checkAllSelectedOrCleared();
      },
      deep: true // Needed to detect changes in nested properties
    }
  },

  methods: {
    ...mapActions(['updateSharedDataOrganization']),

    handleSwitchChange(item) {
      // item.isOn is automatically updated via v-model, so no need to toggle manually
      const value = item.isOn ? '1' : '0';
      this.updateSharedDataOrganization({[item.text]: value});

      const currentSettings = JSON.parse(localStorage.getItem('organizationSettings')) || {};
      currentSettings[item.text] = value;
      localStorage.setItem('organizationSettings', JSON.stringify(currentSettings));
    },

    backPageRoute() {
      this.$router.back();
    },



    complete() {
      const settings = {};

      // Update each item using handleSwitchChange for consistent logic
      this.items.forEach(item => {
        // Call handleSwitchChange to toggle and update each switch
        this.handleSwitchChange(item);

        // Add updated state to settings object
        settings[item.text] = item.isOn ? '1' : '0';
      });

      // After updating each item, we store the final state in Vuex and localStorage
      this.updateSharedDataOrganization(settings);
      localStorage.setItem('organizationSettings', JSON.stringify(settings));
    },

    toggleAll() {
      if (this.selectAll) {
        this.clearSwitches();
      } else {
        this.selectSwitches();
      }
      this.selectAll = !this.selectAll; // Toggle state
    },
    clearSwitches() {
      const settings = {};

      this.items.forEach(item => {
        item.isOn = false;
        settings[item.text] = '0';
      });

      this.updateSharedDataOrganization(settings);
      localStorage.setItem('organizationSettings', JSON.stringify(settings));
    },
    selectSwitches() {
      const settings = {};

      this.items.forEach(item => {
        item.isOn = true;
        settings[item.text] = '1';
      });

      this.updateSharedDataOrganization(settings);
      localStorage.setItem('organizationSettings', JSON.stringify(settings));
    },
    checkAllSelectedOrCleared() {
      // Check if all items are selected or cleared to set the button text
      this.selectAll = this.items.every(item => item.isOn);
    }
  },


  created() {
    // Load initial settings from localStorage if available
    const savedSettings = JSON.parse(localStorage.getItem('organizationSettings'));
    if (savedSettings) {
      this.items.forEach(item => {
        if (savedSettings[item.text] !== undefined) {
          item.isOn = savedSettings[item.text] === '1';
        }
      });
      // Update Vuex with loaded settings from localStorage
      this.updateSharedDataOrganization(savedSettings);
    }
  }
};
</script>


<style scoped>
.v-card-title {
  font-weight: bold;
}

.red-text {
  color: red;
}

</style>
