<template>
  <v-card class="mt-3 card-size pl-3" flat>
    <v-row class="pl-2 pr-6">
      <v-col class="mt-3" cols="7">
        <h3 class="page-title">PRODUCTS</h3>
        <h5 class="light-gray-text">Analyse orders placed in your store</h5>
        <v-card-title>
          
        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="5">
        <v-row class="tool-bar-style">
            <v-btn class="red-text btn-border" size="x-large" variant="text" @click="addProductsPageRoute()">
              <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;ADD PRODUCTS
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card class="px-2 pt-2 mx-1 mb-4 product-details-card" >
      <v-row class="card-two-title px-6 mt-2 mb-4">
        <v-spacer></v-spacer>
        <v-btn class="red-light-text" variant="outlined"  @click="exportPDF">
          <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
        </v-btn>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
          <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
        </v-btn>
      </v-row>
      <v-divider></v-divider>
    
      <v-col cols="12">
          <v-data-table
            v-model:page="page"
            :headers="headers"
            :items="filteredItems"
            :items-per-page="itemsPerPage"
            class="data-table"
            @page="fetchProducts"
            @page-count="totalPages = $event"
            
          >

            <template v-slot:[`item.product_title`]="{ item }">
              <div class="product-column">
                <v-row>
                  <v-col cols="5">
                    <v-img
                        v-if="item.product_image && item.product_image.length > 0"
                        :src="`http://sfa.backend.celata.org/SFA/public/${item.product_image[0].image_url}`"
                        aspect-ratio="1"
                        class="thumbnail-image"
                        @click="selectImage(`http://sfa.backend.celata.org/SFA/public/${item.product_image[0].image_url}`)"
                    ></v-img>
                  </v-col>


                  <v-col class="d-flex align-center" cols="7">
                    <span class="product-title">{{ item.product_title }}</span>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }" >
              <v-row>
                  <v-btn
                      v-if="permissions.read"
                      class="mr-3 bg-blue-lighten-5 viewbtn"
                      color="blue"
                      variant="outlined"
                      @click="viewItem(item)"
                  >
                    <v-icon>mdi-check-circle</v-icon>&nbsp;  view
                  </v-btn>
                  <v-btn
                      v-if="permissions.write"
                      class="mr-3 bg-green-lighten-5 "
                      color="green"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                      v-if="permissions.delete"
                      class="bg-red-lighten-5"
                      color="red"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="confirmDelete(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
              </v-row>
            </template>

            <template v-slot:top>
              <v-row class="mt-2">
                <v-col cols="12" md="4">
                    <v-select
                      v-model="selectedMainCategory"
                      :clear-icon="selectedMainCategory ? 'mdi-close-circle' : null"
                      :items="mainCategories"
                      clearable
                      item-title="name"
                      item-value="id"
                      label="Main Category"
                      placeholder="Select main category"
                      variant="outlined"
                      @update:modelValue="fetchSubCategories"
                    ></v-select>
                  </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="selectedSubCategory"
                    :clear-icon="selectedSubCategory ? 'mdi-close-circle' : null"
                    :items="subCategories"
                    clearable
                    item-title="sub_category_name"
                    item-value="sub_category_name"
                    label="Sub Category"
                    multiple
                    placeholder="Select sub category"
                    variant="outlined"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="search"
                    append-inner-icon="mdi-magnify"
                    clearable
                    hide-details
                    label="Search"
                    placeholder="Search Products"
                    variant="outlined"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>

            
          </v-data-table>
          <!-- <v-row>
          <v-col cols="12" class="text-right">
            <v-pagination
              v-model="page"
              :length="totalPages"
              @input="fetchProducts"
            ></v-pagination>
          </v-col>
        </v-row> -->
        </v-col>
    </v-card>   
  </v-card>
<!-- Delete product confirmation dialog -->
    <v-dialog v-model="delete_product_dialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-1">
        <v-row class="rowClr ma-1" dense>
          <v-col cols="12" md="9">
            <v-card-title class="headline">DELETE PRODUCT</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
            <v-btn icon size="x-small" @click="delete_product_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this product ?</v-card-text>
        <v-card-text class="textspace">
          This product will be deleted immediately. Once deleted, it can no longer be used in any purpose.
        </v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>&nbsp; &nbsp;
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="delete_product_dialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- View Products Dialog -->
    <v-dialog v-model="view_product_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="view_product_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">PROJECTS DETAILS</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="ma-0">
                <v-col cols="10">
                  <v-row>
                    <v-col cols="2">
                      <v-img
                          :src="selectedImage ? selectedImage : ''"
                          aspect-ratio="1"
                          class="product-image-view"
                      ></v-img>
                    </v-col>
                    <v-col class="text-left" cols="10">
                      <h4 class="black-text">{{ product.product_title }}</h4>
                      <h4 class="d-flex align-center">
                        <h2 class="red-text">LKR.{{ product.price }}</h2>
                        <h5>&nbsp;&nbsp; Rs. {{ oldPrice }}</h5>
                        <h5 class="red-text">&nbsp;&nbsp; {{ product.discount_rate }}</h5>
                      </h4>
                      <h4 class="d-flex align-center">
                        <h5>STOCK</h5>
                        <h5 class="red-text">&nbsp;&nbsp; {{ product.available_stock }}</h5>
                      </h4>
                      <v-row class="mt-1">
                        <v-col v-for="(image, index) in product.product_image" :key="index" cols="1">
                          <v-img
                              :src="`http://sfa.backend.celata.org/SFA/public/${image.image_url}`"
                              aspect-ratio="1"
                              class="thumbnail-image"
                              @click="selectImage(`http://sfa.backend.celata.org/SFA/public/${image.image_url}`)"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col cols="2">
                  <v-row class="text-right pa-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="permissions.write"
                        class="mr-3 bg-green-lighten-5 "
                        color="green"
                        icon
                        rounded
                        size="small"
                        variant="outlined"
                        @click="editItem(product)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="permissions.delete"
                        class="bg-red-lighten-5"
                        color="red"
                        icon
                        rounded
                        size="small"
                        variant="outlined"
                        @click="confirmDelete(product)"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                </v-row>
                </v-col>
              </v-row>
              <v-divider class="mt-3 mb-6"></v-divider>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="product.product_title"
                    class="px-3 red-text-field"
                    label="Product Name"
                    readonly
                    variant="outlined">
                  </v-text-field>

                  <v-text-field
                    v-model="product.main_category"
                    class="px-3 red-text-field"
                    label="Main Category"
                    readonly
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.sub_category"
                    class="px-3 red-text-field"
                    label="Sub Category"
                    readonly
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.price"
                    class="px-3 red-text-field"
                    label="Unit Price"
                    readonly
                    variant="outlined"
                  ></v-text-field>
                  <v-textarea
                    v-model="product.product_desc"
                    class="px-3 red-text-field"
                    label="Product Description"
                    readonly
                    variant="outlined"
                  ></v-textarea>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                    v-model="product.unit"
                    class="px-3 red-text-field"
                    label="Unit"
                    readonly
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.size"
                    class="px-3 red-text-field"
                    label="Number of Units"
                    readonly
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                      v-model="product.color"
                      class="px-3 red-text-field"
                      label="Color"
                      readonly
                      variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.available_stock"
                    class="px-3 red-text-field"
                    label="Available Stock (Quantity)"
                    readonly
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                      v-model="product.discount_rate"
                      :value="formattedDiscountRate"
                      class="px-3 red-text-field"
                      label="Discount Rate"
                      readonly
                      variant="outlined"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>      
      </v-card>
    </v-dialog>
    <!-- Edit products dialog -->
    <v-dialog v-model="edit_product_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="clesoDialog">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;Close
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">UPDATE PRODUCT</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="ma-0">
                <v-col cols="10">
                  <v-row>
                    <v-col cols="2">
                      <v-img
                          :src="selectedImage ? selectedImage : ''"
                          aspect-ratio="1"
                          class="product-image-view"
                      ></v-img>
                    </v-col>
                    <v-col class="text-left" cols="10">
                        <h4 class="black-text">{{ product.product_title }}</h4>
                        <h4 class="d-flex align-center">
                          <h2 class="red-text">LKR.{{ product.price }}</h2>
                          <h5>&nbsp;&nbsp; Rs. {{ oldPrice }}</h5>
                          <h5 class="red-text">&nbsp;&nbsp; -{{ product.discount_rate }}%</h5>
                        </h4>
                        <h4 class="d-flex align-center">
                          <h5>STOCK</h5>
                          <h5 class="red-text">&nbsp;&nbsp; {{ product.available_stock }}</h5>
                        </h4>
                      <v-row class="mt-1">
                        <v-col v-for="(image, index) in product.product_image" :key="index" cols="1">
                          <v-img
                              :src="`http://sfa.backend.celata.org/SFA/public/${image.image_url}`"
                              aspect-ratio="1"
                              class="thumbnail-image"
                              @click="selectImage(`http://sfa.backend.celata.org/SFA/public/${image.image_url}`)"
                          ></v-img>
                        </v-col>
                      </v-row>
                      
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <v-row class="text-right pa-3">
                    <v-spacer></v-spacer>
                    <v-btn class="red-background-text" size="large" variant="outlined" @click="updateProduct">UPDATE PRODUCT</v-btn>
                  </v-row>
                </v-col>
              </v-row>
              
              <v-divider class="mt-3 mb-6"></v-divider>
              <v-form ref="form">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="product.product_title"
                    :rules="[v => !!v || 'Product name is required']"
                    class="px-3 mb-2 red-text-field"
                    label="Product Name *"
                    required
                    variant="outlined"
                    >                    
                  </v-text-field>

                  <v-select
                    v-model="product.main_category"
                    :clearable="form.mainCategory !== null && form.mainCategory !== ''"
                    :items="mainCategories"
                    :rules="[v => !!v || 'Main category is required']"
                    class="px-3 mb-2 red-text-field"
                    item-title="name"
                    item-value="id"
                    label="Main Category *"
                    placeholder="Select main category"
                    required
                    variant="outlined"
                    @update:modelValue="fetchSubCategories()"
                  ></v-select>

                  <v-select
                    v-model="product.sub_category"
                    :items="subCategories"
                    :loading="productLoading"
                    :rules="[v => !!v || 'Sub category is required']"
                    class="px-3 mb-2 red-text-field"
                    item-title="sub_category_name"
                    item-value="sub_category_name"
                    label="Sub Category *"
                    required
                    variant="outlined"
                  ></v-select>
                  
                  <v-text-field
                    v-model="product.price"
                    :rules="[v => !isNaN(Number(v)) || 'Price must be a number']"
                    class="px-3 mb-2 red-text-field"
                    label="Unit Price *"
                    required
                    variant="outlined"
                  ></v-text-field>

                  <v-card flat width="100%">
                <div class="d-flex justify-space-between pa-4 pb-0">
                  <!-- Text Formatting Buttons -->
                  <v-btn-toggle v-model="formatting" divided multiple variant="outlined">
                    <v-btn @click="applyFormat('italic')">
                      <v-icon icon="mdi-format-italic"></v-icon>
                    </v-btn>

                    <v-btn @click="applyFormat('bold')">
                      <v-icon icon="mdi-format-bold"></v-icon>
                    </v-btn>

                    <v-btn @click="applyFormat('underline')">
                      <v-icon icon="mdi-format-underline"></v-icon>
                    </v-btn>

                    <!-- Text Color Option -->
                    <v-menu
                        v-model="showColorPicker"
                        close-on-content-click
                        location="end"
                    >

                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">
                          <div class="d-flex align-center flex-column justify-center">
                            <v-icon icon="mdi-format-color-text"></v-icon>
                            <v-sheet :color="selectedColor" height="4" tile width="26"></v-sheet>
                          </div>
                        </v-btn>
                      </template>

                      <!-- Color Picker -->
                      <v-color-picker
                          v-model="selectedColor"
                          flat
                          hide-canvas
                          @change="applyFormat('color')"
                      ></v-color-picker>
                    </v-menu>
                  </v-btn-toggle>

                  <!-- Text Alignment Buttons -->
                  <v-btn-toggle v-model="alignment" divided variant="outlined">
                    <v-btn @click="setAlignment('center')">
                      <v-icon icon="mdi-format-align-center"></v-icon>
                    </v-btn>

                    <v-btn @click="setAlignment('left')">
                      <v-icon icon="mdi-format-align-left"></v-icon>
                    </v-btn>

                    <v-btn @click="setAlignment('right')">
                      <v-icon icon="mdi-format-align-right"></v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>

                <!-- Text Area -->
                <v-sheet class="pa-4 text-center">
                  <!-- <v-textarea
                      v-model="lead.description"
                      class="mb-2"
                      label="Itineraries Description *"
                      placeholder="Write a description here..."
                      :style="textareaStyles"
                      variant="outlined"
                      :rules="[v => !!v || 'Description is required']"
                  ></v-textarea> -->
                  <v-textarea
                  v-model="product.product_desc"
                  :style="textareaStyles"
                  class="px-3 mb-2 red-text-field"
                  label="Product Description *"
                  variant="outlined"
                  ></v-textarea>
                </v-sheet>
              </v-card>

                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="product.unit"
                    :items="units"
                    class="px-3 mb-2 red-text-field"
                    item-title="Unit"
                    item-value="name"
                    label="Unit *"
                    required
                    variant="outlined"
                    >
                  </v-select>
                    <v-text-field
                    v-model="product.size"
                    class="px-3 mb-2 red-text-field"
                    label="Numbers of Units *"
                    variant="outlined"
                  ></v-text-field>
                  <!-- <v-text-field
                    label="Color *"
                    v-model="product.color"
                    variant="outlined"
                    class="px-3 red-text-field"
                  ></v-text-field> -->
                  <p>Selected Color: {{ getColorName(product.color) }}</p>
                  <v-text-field
                    v-model="product.color"
                    class="mb-2 px-3"
                    label="Color *"
                    variant="outlined"
                    @click="dialog = true"
                  ></v-text-field>

                  <v-dialog v-model="dialog" max-width="400" persistent>
                    <v-card class="dialog-card-style px-3">
                      <v-card-title>Select Color</v-card-title>
                      <v-card-text>
                        <v-color-picker
                          v-model="product.color"
                          v-model:mode="mode"
                          class="mb-6"
                        ></v-color-picker>
                        <v-select
                          v-model="mode"
                          :items="modes"
                          variant="outlined"
                        ></v-select>
                      </v-card-text>
                      <v-btn block class="red-background-text-btn px-9 mb-6" variant="flat" @click="dialog = false"><v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;&nbsp;&nbsp;ADD</v-btn>
                    </v-card>
                  </v-dialog>
                  <v-text-field
                    v-model="product.available_stock"
                    :rules="[v => !isNaN(Number(v)) || 'Available stock required and must be a number']"
                    class="px-3 mb-2 red-text-field"
                    label="Available Stock (Quantity) *"
                    required
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="product.discount_rate"
                    :rules="[v => !isNaN(Number(v)) || 'Discount rate must be a number', v => Number(v) >= 0 || 'Discount rate must be 0 or higher', v => Number(v) < 100 || 'Discount rate must be less than 100']"
                    class="px-3 mb-2 red-text-field"
                    label="Discount Rate *"
                    variant="outlined"
                  ></v-text-field>
                  <v-card flat>
                    <h5 class="px-3">Product Images</h5>
                <div
                  class="drop-area"
                  @click="triggerFileInput"
                  @drop.prevent="onDrop"
                  @dragover.prevent="onDragOver"
                >
                  <input
                    ref="fileInput"
                    accept="image/*"
                    class="hidden-file-input"
                    multiple
                    type="file"
                    @change="onFileChange"
                  />
                  <div
                    v-for="(image, index) in previewImages"
                    :key="index"
                    class="image-card-container"
                  >
                    <v-card
                      class="image-card"
                    >
                      <v-img
                        :alt="'Image ' + (index + 1)"
                        :src="image"
                        class="image-preview"
                      ></v-img>
                      <v-btn
                        class="remove-icon"
                        icon
                        size="x-small"
                        @click.stop="removeImage(index)"
                      >
                        <v-icon color="red" size="small">mdi-delete</v-icon>
                      </v-btn>
                    </v-card>
                  </div> 
                  <div
                    v-if="previewImages.length === 0"
                    class="placeholder-container"
                  >
                    <v-icon size="36">mdi-cloud-upload</v-icon>
                    <span>Drag your photo here OR click to browse from device</span>
                  </div>
                </div>
              </v-card>
                 
                </v-col>
              </v-row>
            </v-form>
            </v-card>
          </v-col>
        </v-row>
        
      </v-card>
    </v-dialog>
      <!-- Snackbar to show notifications -->
   <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :right="snackAlert.snackBarRight"
      :top="snackAlert.snackBarTop"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
    
  </template>
  
  <script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import * as XLSX from "xlsx";
  import ntc from 'ntc';
  import {getPermissions} from "@/plugins/permissions";

  export default {
    components: {
     
    },
    name: 'ProductsList',
    data() {
      return {

        formatting: [],
        showColorPicker: false,
        selectedColor: '#000',
        alignment: '',
        textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
        },

        loading:false,
        form: {
          images: []
        },
        //search
        search: '',

        selectedMainCategory: null,
        selectedSubCategory: [],

        // Pagination
        itemsPerPage: 10,
        page: 1,
        totalPages: 0,

       
        headers: [
          { text: 'PRODUCT NAME', value: 'product_title', title: 'PRODUCT NAME', sortable: true },
          { text: 'MAIN CATEGORY', value: 'main_category', title: 'MAIN CATEGORY', sortable: true },
          { text: 'SUB CATEGORY', value: 'sub_category', title: 'SUB CATEGORY', sortable: true },
          { text: 'PRICE', value: 'price', title: 'PRICE', sortable: true },
          { text: 'STOCK AVAILABILITY', value: 'available_stock', title: 'STOCK AVAILABILITY', sortable: true },
          { text: 'ACTION', value: 'action', sortable: false, title: 'ACTION' },
        ],


         // Dialog state
        delete_product_dialog: false,
        view_product_dialog: false,
        edit_product_dialog: false,
        productLoading:false,

        selectedProductId: null,
        product: {},

        numericRule: value => !isNaN(parseFloat(value)) || 'Price must be a number',

        item:[],

        units: [
          { Unit: 'Grams  (g)', name: 'g' },
          { Unit: 'Kilograms  (Kg)', name: 'Kg' },
          { Unit: 'Liters  (L)', name: 'L' },
          { Unit: 'Milliliters  (Ml)', name: 'Ml' },
          { Unit: 'Centimeters  (Cm)', name: 'Cm' },
          { Unit: 'Meters  (M)', name: 'M' }
        ],


        mainCategories: [],
        subCategories: [],

        // List of products
        items: [],  

        // Additional fields
        product_image: [], // List of images for upload

        // Image handling
        previewImages: [],
        selectedImage: '',

        snackAlert: {
          snackbar: false, // Snackbar visibility
          snackColor: "", // Snackbar color
          snackMessage: "", // Snackbar message
          snackBarRight: false, // Snackbar position right
          snackBarTop: true,  // Snackbar position top
        },

        productcheck: null,

        dialog: false,
        // mode: 'hsla',
        mode: 'RGB',
        // modes: ['hsla', 'rgba', 'hexa'],
        modes: ['RGB', 'HSL'],

        removedImages: [],
        permissions: {},

      };
    },
    created() {
      // this.fetchCategories();
      this.fetchProducts();
      this.fetchMainCategories();
    },
    methods: {

      applyFormat(format) {

        if (format === 'italic') {
          this.textareaStyles.fontStyle =
              this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
        } else if (format === 'bold') {
          this.textareaStyles.fontWeight =
              this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
        } else if (format === 'underline') {
          this.textareaStyles.textDecoration =
              this.textareaStyles.textDecoration === 'underline'
                  ? 'none'
                  : 'underline';
        } else if (format === 'color') {
          this.textareaStyles.color = this.selectedColor;
        }
        },

        setAlignment(align) {
        this.textareaStyles.textAlign = align;
        },

      getColorName(hex) {
      const color = ntc.name(hex); 
      return color[1];
      },
      sizeValidation(value) {
        const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
        return sizePattern.test(value.trim());
      },
      exportExcel() {
        // Map filtered data for Excel format with additional formatting
        const tableData = this.filteredItems.map((item) => ({
         
          'Product Name': item.product_title,
          'Main Category': item.main_category,
          'Sub Category': item.sub_category,
          'Price': item.price,
          'Stock Availability': item.available_stock,
          'Color': item.color,
          'Discount Rate': `${item.discount_rate}%`,
          'Size': item.size,
          'Unit': item.unit,
        }));

        // Convert JSON data to Excel sheet
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set column widths
        const columnWidths = [
          { wpx: 50 },  
          { wpx: 150 }, 
          { wpx: 120 }, 
          { wpx: 120 }, 
          { wpx: 80 }, 
          { wpx: 100 },
          { wpx: 80 },  
          { wpx: 110 },
          { wpx: 80 },  
          { wpx: 70 },  
        ];
        worksheet['!cols'] = columnWidths;

        // Style header cells
        Object.keys(worksheet).forEach(cell => {
          if (cell.startsWith('A1')) {
            worksheet[cell].s = {
              font: { bold: true, color: { rgb: "FFFFFF" } },
              fill: { fgColor: { rgb: "1F4E78" } },
              alignment: { horizontal: "center" },
            };
          }
        });

        // Create workbook and append the sheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Products Analysis');

        // Write and download Excel file
        XLSX.writeFile(workbook, 'products_analysis.xlsx');
      },


      exportPDF() {
        const doc = new jsPDF();

        // Set the document title with larger font size and bold style
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        doc.text('Product Analysis', 14, 20);

        // Add a subtitle or additional text if needed
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
        doc.text('A comprehensive analysis of product data', 14, 28);

        // Prepare the data for the table using filtered items
        const tableData = this.filteredItems.map((item, index) => [
          index + 1,
          item.product_title,
          item.main_category,
          item.sub_category,
          item.price,
          item.available_stock,
          item.color,
          `${item.discount_rate}%`,
          item.size,
          item.unit,
        ]);

        // Define the columns for the table with specific widths
        const tableColumns = [
          { header: '#', dataKey: 'index' },
          { header: 'Product Name', dataKey: 'product_title' },
          { header: 'Main Category', dataKey: 'main_category' },
          { header: 'Sub Category', dataKey: 'sub_category' },
          { header: 'Price', dataKey: 'price' },
          { header: 'Stock', dataKey: 'available_stock' },
          { header: 'Color', dataKey: 'color' },
          { header: 'Discount Rate', dataKey: 'discount_rate' },
          { header: 'Size', dataKey: 'size' },
          { header: 'Unit', dataKey: 'unit' },
        ];

        // Generate table with custom styles
        doc.autoTable({
          head: [tableColumns.map(col => col.header)],   
          body: tableData,                               
          startY: 35,
          startX: 5,                                   
          headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255], fontSize: 8 },  
          bodyStyles: { fontSize: 8 },                 
          columnStyles: {
            0: { cellWidth: 10 },                       
            1: { cellWidth: 30 },                      
            2: { cellWidth: 25 },                        
            3: { cellWidth: 25 },                       
            4: { cellWidth: 15 },                        
            5: { cellWidth: 20 },                        
            6: { cellWidth: 15 },                        
            7: { cellWidth: 20 },                        
            8: { cellWidth: 15 },                        
            9: { cellWidth: 15 },                        
          },
          styles: { cellPadding: 3, overflow: 'linebreak' }, 
          theme: 'striped',                             
          didDrawPage: function (data) {                 
            let pageSize = doc.internal.pageSize;
            let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text('Page ' + doc.internal.getNumberOfPages(), data.settings.margin.left, pageHeight - 10);
            doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 15);
          },
        });

       
        

        // Save the PDF with a meaningful filename
        doc.save('Product_Analysis_Report.pdf');
      },


      showSnackbar(message, color) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
      },

      fetchMainCategories() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token'); 

        axios.post('/api/mainProduct', {
            userId: userId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            let sample = response.data.data;
            this.mainCategories = sample.map(item => {
                return {
                    id: item.id,
                    name: item.main_category
                };
            });
            console.log('Main categories:', this.mainCategories);
        })
        .catch(error => {
            console.error('Error fetching main categories:', error);
        });
    },

    clesoDialog(){
      this.edit_product_dialog = false;
      this.selectedMainCategory = null;
      this.product.main_category = null;

    },


      fetchSubCategories() {
        // if (!this.product.main_category) return;
         
          const token = localStorage.getItem('token');
          const userId = localStorage.getItem('userId');

         
          this.productLoading = true;
          this.selectedSubCategory = null;
          this.product.sub_category = null;
          let mainCatagoryId = this.selectedMainCategory || this.product.main_category
          // const mainCatagoryId = this.selectedMainCategory;
          // console.log('Main Category ID:', this.product.main_category);
          // console.log('Main Category ID t:', this.selectedMainCategory);

          console.log('MainID', mainCatagoryId);
          console.log('mainCatagaryName', this.product.main_category);

          axios.post(`/api/subcatagoryProduct`,
          { userId: userId,
            mainCatagoryId: mainCatagoryId,
            mainCatagaryName:this.product.main_category
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
         
            this.subCategories = response.data.data;
            console.log('Subcategories:', this.subCategories);

            // Find and set the main category name
            const selectedCategory = this.mainCategories.find(cat => cat.id === mainCatagoryId);

            this.product.main_category_name = selectedCategory ? selectedCategory.name : '';

            this.selectedMainCategory = selectedCategory ? selectedCategory.name : '';
            this.productLoading = false;
            console.log('Selected Main Category Name:', this.product.main_category_name);
          })
          .catch(error => {
              console.error('Error fetching sub categories:', error);
              this.productLoading = false;
          });
      },

      getCategoryName(categoryId) {
        console.log('Looking up categoryId:', categoryId);
        if (typeof categoryId === 'string') {
          categoryId = parseInt(categoryId, 10); // Convert string ID to number if needed
        }
        const category = this.mainCategories.find(cat => cat.id === categoryId);
        console.log('Found category:', category);
        return category ? category.name : 'Unknown';
      },

      async updateProduct() {
        const form = this.$refs.form;
        const isValid = form.validate();

        if (!isValid) {
          // If the form is not valid, show an error message and return
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
          return;
        }

        const formData = new FormData();
        formData.append('productId', this.product.id);
        formData.append('productTitle', this.product.product_title);
        formData.append('productDescription', this.product.product_desc);
        formData.append('mainCatagory', this.product.main_category_name);
        formData.append('subCatagory', this.product.sub_category);
        formData.append('unit', this.product.unit);
        formData.append('size', this.product.size);
        formData.append('color', this.product.color);

        const colorName = this.getColorName(this.product.color);
        formData.append('color', colorName);
        formData.append('price', this.product.price);
        formData.append('discountRate', this.product.discount_rate);
        formData.append('availableStaock', this.product.available_stock);
        formData.append('userId', localStorage.getItem('userId'));


        console.log('Images array:', this.product.images);

        // Convert images to Base64 and append them to FormData
        try {

          // Convert new images to base64
          const base64Images = await Promise.all(
              this.form.images.map(file => this.convertToBase64(file))
          );

          // Filter out removed images from previously existing images
          const previousImages = this.product.product_image
              .filter(img => !this.removedImages.includes(img.image_url))
              .map(img => ({ image: img.image_url }));

          // Combine previous images and new base64 images
          const allImages = [
            ...previousImages, // Only the images that were not removed
            ...base64Images.map(image => ({ image })) // New images (converted to base64)
          ];

          const imagesJson = JSON.stringify(allImages);
          formData.append('productImage', imagesJson);

          for (let [key, value] of formData.entries()) {
              console.log(`${key}:`, value);
          }

          // Send FormData to the API
          const token = localStorage.getItem('token');
          const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/addProduct`, formData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });

          if (response.data.success) {
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'success';
            this.snackAlert.snackMessage = 'Product updated successfully';
            this.view_product_dialog = false;
            this.edit_product_dialog = false;
            this.fetchProducts();
            this.product.main_category_name='';
            this.selectedMainCategory='';
          } else {
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'error';
            this.snackAlert.snackMessage = 'Failed to update product';
            this.fetchProducts();
          }
        } catch (error) {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Please fill out all required fields correctly';
          console.error('Error updating product:', error);
        }
      },

      // Helper method to convert a file to a Base64 string
      convertToBase64(file) {
        return new Promise((resolve, reject) => {
          if (file instanceof Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const base64String = reader.result.split(',')[1]; // Remove the prefix
              resolve(base64String);
            };
            reader.onerror = error => reject(error);
          } else {
            reject(new Error('Invalid file type'));
          }
        });
      },
     
      homeReload(){
        window.location.href = '/dashboard';
      },

      fetchProducts() {

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem("token");

        this.loading = true;

        const formData = new FormData();
        formData.append("userId", userId);

        axios.post('/api/getProductList',formData,{
          headers: {
                'Authorization': `Bearer ${token}`
            }

        })
          .then(response => {
            // Sort the data by created_at in descending order (newest first)
            this.items = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            this.loading =false;
            // this.items = response.data.data;
            console.log('Result', response.data.data);
            // this.subCategories = response.data.subCategories;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
            this.loading =false;
          });
      },

      addProductsPageRoute(){
        this.$router.push({ path: '/add-products' });
      },



      viewItem(item) {
        this.selectedProductId = item.id;
        this.product = { ...item }; // Create a copy of the item

        // Prepend the URL to the selected image if it exists
        this.selectedImage = item.product_image.length > 0
            ? `http://sfa.backend.celata.org/SFA/public/${item.product_image[0].image_url}`
            : '';

        // Prepend the URL for all preview images
        this.previewImages = item.product_image.map(image => ({
          ...image,
          image_url: `http://sfa.backend.celata.org/SFA/public/${image.image_url}`
        })) || [];

        this.view_product_dialog = true;
        console.log("View item:", item);
      },


      editItem(item) {
        this.edit_product_dialog = true;
        this.selectedProductId = item.id;
        this.product = { ...item }; // Create a copy of the item

        // Prepend the URL to the selected image if it exists
        this.selectedImage = item.product_image.length > 0
            ? `http://sfa.backend.celata.org/SFA/public/${item.product_image[0].image_url}`
            : '';

        // Prepend the URL for all preview images
        this.previewImages = item.product_image.map(img =>
            `http://sfa.backend.celata.org/SFA/public/${img.image_url}`
        ) || [];

        // Clear any new images to start fresh
        this.form.images = [];

        console.log('Editing item:', item);
        console.log("Edit item:", item);
      },


      confirmDelete(item) {
        this.delete_product_dialog = true;
        this.selectedProductId = item.id;
        console.log('Deleting item:', this.selectedProductId);
      },
      deleteItem() {
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');
          const ProductID = this.selectedProductId;
          console.log('delete id', ProductID);

          axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteProduct`, {
            userId: userId,
            ProductID: ProductID,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(() => {
            // Handle success - remove the deleted item from the table data
            this.fetchProducts(); // Refresh the user list
            this.delete_product_dialog = false; // Close the confirmation dialog
            this.view_product_dialog = false;
            console.log('product deleted successfully');
            this.showSnackbar("Product delete successfully!", "success");
          })
          .catch((error) => {
            console.log('Error deleting Product', error);
            this.delete_product_dialog = false;
            this.fetchProducts();
          });
        },
      backPageRoute(){
        this.$router.back();
      },
      selectImage(image) {
        this.selectedImage = image;
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      onFileChange(event) {
        const files = event.target.files;
        this.handleFiles(files);
      },
      onDrop(event) {
        const files = event.dataTransfer.files;
        this.handleFiles(files);
      },
      onDragOver(event) {
        event.preventDefault();
      },

      handleFiles(files) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));

        if (fileArray.length === 0) {
            alert('Only image files are allowed!');
            return;
        }

        // Ensure each item in the array is a File object
        fileArray.forEach(file => {
            if (!(file instanceof File)) {
                console.error('Not a valid File object:', file);
            }
        });

        // Add new files to form images
        this.form.images.push(...fileArray);

        // Preview the new image URLs alongside the existing ones
        this.previewImages.push(...fileArray.map(file => URL.createObjectURL(file)));
    },


      removeImage(index) {
        let removedImage = this.previewImages[index];

        // Remove the specified prefix from removedImage
        const prefix = 'http://sfa.backend.celata.org/SFA/public/';
        removedImage = removedImage.replace(prefix, '');

        // Debug: Log to confirm URLs after prefix removal
        console.log('Product Image URLs (stripped):', this.product.product_image.map(img => img.image_url.replace(prefix, '')));
        console.log('Removed Image (stripped):', removedImage);

        // Check for the removed image by matching the stripped URL
        const existingImage = this.product.product_image.find(
            img => img.image_url.replace(prefix, '') === removedImage
        );

        // If a match is found, add it to removedImages
        if (existingImage) {
          this.removedImages.push(existingImage.image_url);
        } else {
          console.warn('Image not found in product images:', removedImage);
        }

        // Log all data for debugging
        console.log('Matched Existing Image:', existingImage);
        console.log('Removed Images Array:', this.removedImages);

        // Remove from form and preview lists
        this.form.images.splice(index, 1);
        this.previewImages.splice(index, 1);
      },



    },

    mounted() {

      const dashboardId = 2;
      this.permissions = getPermissions(dashboardId);
      console.log("Permissions:", this.permissions);

      if (this.product.product_image && this.product.product_image.length > 0) {
        this.selectedImage = this.product.product_image[0];
      }


    },

    computed: {
      formattedDiscountRate() {
        return this.product.discount_rate + '%'; 
      },
      oldPrice() {
        const price = parseFloat(this.product.price);
        const discountRate = parseFloat(this.product.discount_rate);
        if (discountRate > 0) {
          return (price / (1 - discountRate / 100)).toFixed(2);
        }
        return price.toFixed(2); // If no discount, old price is the current price
      },
      
      filteredItems() {
      return this.items.filter(product => {
        const matchesMainCategory = !this.selectedMainCategory || product.main_category === this.selectedMainCategory;

        // Check if the selectedSubCategory is an array (because it's now allowing multiple selections)
        const matchesSubCategory = !this.selectedSubCategory || this.selectedSubCategory.length === 0 || 
          this.selectedSubCategory.includes(product.sub_category_name) || 
          this.selectedSubCategory.includes(product.sub_category);

        const matchesSearch = !this.search || 
          product.product_title.toLowerCase().includes(this.search.toLowerCase()) ||
          (product.main_category && product.main_category.toLowerCase().includes(this.search.toLowerCase())) ||
          (product.sub_category && product.sub_category.toLowerCase().includes(this.search.toLowerCase())) ||
          (product.price && product.price.toString().toLowerCase().includes(this.search.toLowerCase())) ||
          (product.available_stock && product.available_stock.toString().toLowerCase().includes(this.search.toLowerCase()));

        return matchesMainCategory && matchesSubCategory && matchesSearch;
      });
      }

    },

  };
  </script>
  
  <style scoped>
  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  }

  .data-table {
    width: 100% !important;
    overflow-y: hidden !important;
  }

  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 
  .product-details-card{
    height: fit-content;
    padding-bottom: 20px;
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: scroll !important;
    max-height: 80vh;
  }
    
  .divider-margin-dv{
    margin-top: -20px;
  }
  .card-size{
    width: 100% !important;
    padding: 0;
    margin-bottom: 0;
  }
  .page-title {
    font-weight: bold;
  }

  .light-gray-text{
    color: #a09e9e !important;
  }

  .red-text {
    color: #b00d0d;
  }

  .black-text{
    color:#000000 !important;
  }
  
  .gray-text {
    color: #757575 !important;
  }
  
  .btn-border{
    border-block-end-color: #9a1313;
  }
  .tool-bar-style {
    display: flex;
    justify-content: end;
  }

  .divider-vertical{
    color: #3c3b3b !important;
  }
  .card-two-title{
    color: black;
  }
  .v-card-title {
    font-weight: bold;
  }
  .red-text {
    color: #b00d0d;
  }
  .red-light-text {
    background-color: #f1cccc;
    color: #b00d0d;
  }
  .green-light-text {
    background-color: #aee8b1;
    color: #057b0a;
  }
  .green-text {
    color: #057b0a;
    background-color: #aee8b1;
  }

  .product-image {
    width: 70px;
    height: 70px;
    margin-left: 10px;
    padding: 4px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    border-width: 4px;
    background-color: white;
    margin: 10px;
  }

  .product-image-view {
    width: 150px;
    height: 150px;
    margin-left: 0px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    border-width: 1px;
    background-color: white;
  }

  .thumbnail-image {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    border-width: 1px;
    background-color: white;
  }

  .drop-area {
    border: 2px dashed #9a1313;
    padding: 20px;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    gap: 10px;
  }

  .hidden-file-input {
    display: none;
  }

  .image-card-container {
    margin: 0 8px 8px 0;
  }

  .image-card {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between image cards */
  }

  .placeholder-container {
    width: 100%;
    height: 120px;
    text-align: center;
    color: #9a1313;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .red-text-content .v-input__control .v-input__slot,
  .red-text-content .v-input__control .v-input__slot .v-text-field__details,
  .red-text-content .v-input__control .v-input__slot input,
  .red-text-content .v-input__control .v-input__slot textarea {
    color: red !important;
  }

  .rowClr{
    background-color: #d9d9d9;
  }

  .textspace{
    margin-top: -20px;
  }

  .headline {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .dialog-card-style{
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
  }

  .red-background-text-btn {
    background-color: #f0bcbc;
    color: #BB0000;
    margin-top: -27px;
    margin-bottom: 10px;
    margin-right: 30px;
    margin-left: 30px;
  } 
  .viewbtn{
      font-size: 12px;
    }

    :deep(.dataTable) th, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
      overflow-y: hidden;
      max-width: 12vw;
}

:deep(.dataTable) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  overflow: hidden;
  max-width: 12vw;
}

:deep(.dataTable) {
  border: white;
  overflow: hidden;
  
}

:deep(.dataTable )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.dataTable )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
.remove-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: white;
  border-radius: 50%; /* This will make the background round */
  padding: 4px; /* Adjust as needed to control the icon size */
}

.remove-icon .v-icon {
  color: red;
}
</style>
  