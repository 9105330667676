<!-- Reset Password Component -->
<template>

<v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
    <v-progress-circular
      color="green"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>


  <v-app>
    <v-container class="pa-0 ma-0 d-flex fill-height" fluid>
      <v-row class="fill-height" no-gutters>
        <!-- Image Section -->
        <v-col
            class="image-container pa-0 ma-0"
            cols="12"
            lg="5"
            md="12"
            sm="12"
            xl="5"
            xs="12"
        >
          <img
              :src="require('@/assets/ASSETS/forgot_password_2.png')"
              class="custom_web_logo"
              cover
          />
          <img
              :src="require('@/assets/LOGOS/logo_main.png')"
              class="overlay_logo"
          />
        </v-col>

        <!-- Forgot Password Form Section -->
        <v-col
            class="d-flex align-center justify-center pa-0 ma-0"
            cols="12"
            lg="7"
            md="12"
            sm="12"
            xl="7"
            xs="12"
        >
          <v-container class="fill-height" color="white" fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" md="2" sm="12"></v-col>
              <v-col cols="12" md="8" sm="12">
                <v-card color="white" elevation="0">
                  <v-card-title class="font-weight-bold">
                    Change Your Password?
                  </v-card-title>

                  <v-card-subtitle class="mt-5">
                    <h4><v-icon left small>mdi-lock-open-outline</v-icon>&nbsp;
                      Please provide your new password details according to the system rules.</h4>
                  </v-card-subtitle>

                  <v-card-text class="mt-2">
                    <v-form ref="form" v-model="valid">

                      <v-text-field
                          v-model="password"
                          :rules="passwordRules"
                          class="mt-2"
                          dense
                          label="New Password"
                          outlined
                          placeholder="New Password"
                          prepend-inner-icon="mdi-lock-outline"
                          required
                          type="password"
                          variant="outlined"
                      ></v-text-field>

                      <v-text-field
                          v-model="confirmPassword"
                          :rules="confirmPasswordRules"
                          class="mt-3 mb-3"
                          dense
                          label="Re-Enter"
                          outlined
                          placeholder="Re-Enter New Password"
                          prepend-inner-icon="mdi-lock-outline"
                          required
                          type="password"
                          variant="outlined"
                      ></v-text-field>

                      <v-divider></v-divider>

                      <v-row class="mt-5">
                        <v-col cols="6" >
                          <v-btn
                              block
                              class="white--text"
                              color="error"
                              size="large"
                              @click="forgotPasswordVerifyModule"
                          >
                            <v-icon left>mdi-account-arrow-right-outline</v-icon>
                            &nbsp;Reset Password
                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                              block
                              size="large"
                              variant="tonal"
                              @click="backToLogIn"
                          >
                            <v-icon left small>mdi-keyboard-backspace</v-icon>&nbsp;
                            &nbsp;Back to Login
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2" sm="12"></v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog_components" max-width="450px">
        <v-card class="text-left" >
          <v-container class="align-left justify-left" color="white">
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-card color="white" flat>
                  <v-card-title class="font-weight-bold">
                    Password Reset Successfully!
                  </v-card-title>

                  <v-row class="black--text">
                    <v-col cols="12">
                      <v-btn
                          block
                          class="white--text mt-5"
                          color="error"
                          size="large"
                          @click="backToLogIn"
                      >
                        <v-icon left small>mdi-keyboard-backspace</v-icon>
                        &nbsp;Back to Login
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :right="snackAlert.snackBarRight"
        :top="snackAlert.snackBarTop"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}

    </v-snackbar>
  </v-app>
</template>

<script>

import axios from "axios";

export default {
  name: 'ResetPassword',
  data() {
    return {
      valid: false,
      dialog_components: false,
      centerLoading:false,
      password: '',
      confirmPassword: '',
      passwordRules: [
        value => !!value || 'Please enter your new password',
        value => value.length >= 6 || 'Password must be at least 6 characters',
      ],
      confirmPasswordRules: [
        value => !!value || 'Please confirm your password',
        value => value === this.password || 'Passwords do not match',
      ],
      snackAlert: {
        snackbar: false,
        snackColor: "",
        snackMessage: "",
        snackBarRight: false,
        snackBarTop: true,
      },
    };
  },
  methods: {
    backToLogIn() {
      this.$router.push('/');
    },
    forgotPasswordVerifyModule() {
      this.resetPassword();
      

    },
    async resetPassword() {
      try {
        // Use URLSearchParams to extract the token from the query string
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token'); // Extract 'token' from the URL
        this.centerLoading = true;
        // Check if the token exists
        if (token) {
          console.log("Extracted Token:", token);
        } else {
          console.error("Token not found in the URL.");
          return; // Exit the function if the token is not found
        }

        // Make the API call to reset the password
        const response = await axios.post('/api/passwords/reset', {
          token: token,
          password: this.password,
          password_confirmation: this.confirmPassword,
        });

        // Check if the request was successful
        if (response.status === 200) {
          console.log("Password reset successful:", response);
          this.centerLoading = false;
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'success';
          this.snackAlert.snackMessage = 'password reset successfully';
          this.dialog_components=true;
        } else {
          console.error("Error resetting password:", response.statusText);
          this.centerLoading = false;
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'password reset Failed';
          this.dialog_components=true;
        }
      } catch (error) {
        // Catch and log any errors
        console.error("Error:", error);
        this.centerLoading = false;
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'password reset Failed';
          this.dialog_components=true;
      }
    },



  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full height */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Prevent scrolling */
  background-color: #f5f5f5; /* Background color to match provided UI */
}

.custom_web_logo {
  width: 100%;
  height: 100%;
}

.fill-height {
  height: 100vh; /* Full viewport height */
}

.pa-0 {
  padding: 0 !important;
}

.ma-0 {
  margin: 0 !important;
}

.centered-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media (max-width: 960px) {
  .image-container {
    display: none; /* Hide the image on smaller screens */
  }
}
.overlay_logo {
  position: absolute;
  top: 45px;
  left: 45px;
  width: 220px;
  height: auto;
}
</style>
  