<!-- Top selling products - main dashboard second component -->

<template>
  <v-row>
    <v-col cols="12">
      <v-card class="pa-3">
        <v-card-title class="card-h4-title">
          TOP SELLING PRODUCTS
          <div class="button-style">
              <v-date-input
                  v-model="dates"
                  append-inner-icon="mdi-close"
                  color="red"
                  label="Select Date Range"
                  max-width="368"
                  multiple="range"
                  prepend-icon=""
                  prepend-inner-icon="$calendar"
                  width="300"
                  @click:append-inner="clearDateRange"
              ></v-date-input>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
              <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </div>
        </v-card-title>

        <v-divider class="mt-3"></v-divider>

        <!-- Top selling products data table -->
        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="filteredRoutes"
              class="data-table gray-text"
              item-value="name"
              items-per-page="5"
          >

            <!-- Product Name Column -->
            <template v-slot:[`item.products`]="{ item }">
              <div class="products-column">
                <div v-for="(product, index) in item.products" :key="index" class="d-flex align-center">
                  <v-img
                      v-if="product.product?.product_image && product.product?.product_image?.length > 0"
                      :src="`http://sfa.backend.celata.org/SFA/public/${product.product?.product_image[0]?.image_url}`"
                      class="product-image mr-3"
                      max-height="50"
                      max-width="50"
                  ></v-img>
                  <span>{{ product.product_name }}</span>
                </div>
              </div>
            </template>

            <!-- Price Column -->
            <template v-slot:[`item.products.unit_price`]="{ item }">
              <div class="price-column">
                <div v-for="(product, index) in item.products" :key="index">
                  <div>{{ product.unit_price }}</div>
                  <v-divider class="my-1"></v-divider>
                </div>
              </div>
            </template>

            <!-- Quantity Sold Column -->
            <template v-slot:[`item.quantity_sold`]="{ item }">
              <div class="quantity-column">
                <span>{{ item.quantity_sold }}</span>
              </div>
            </template>

            <!-- Sales Amount Column -->
            <template v-slot:[`item.sales_amount`]="{ item }">
              <div class="amount-column">
                <span>{{ item.sales_amount }}</span>
              </div>
            </template>

            <!-- Outlets Name Column -->
            <template v-slot:[`item.store_name`]="{ item }">
              <div class="outlet-column">
                <span>{{ item.store_name }}</span>
              </div>
            </template>

            <!-- Assigned Agent Column -->
            <template v-slot:[`item.agent`]="{ item }">
              <div class="agent-column">
                <span>{{ item.agent.name }}</span>
                <div v-if="item.agent_id" class="red-text">ID: {{ item.agent.registration_num }}</div>
              </div>
            </template>

          </v-data-table>
        </v-col>
      </v-card>


    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
export default {
  name: 'TopSellingProducts',
  data() {
    return {
      
      weeklyMenu: false,
      weeklyDate: null,
      monthlyMenu: false,
      monthlyDate: null,
      topSellingHeaders: [
        { text: 'PRODUCT NAME', value: 'order_id', title: 'PRODUCT NAME' },
        { text: 'PRICE', value: 'sales_amount', title: 'PRICE' },
        { text: 'QUANTITY', value: 'quantity_sold', title: 'QUANTITY' },
        { text: 'AMOUNT', value: 'paid_amount', title: 'AMOUNT' },
        { text: 'OUTLETS NAME', value: 'store_name', title: 'OUTLETS NAME' },
        { text: 'ASSIGNED AGENT', value: 'agent', title: 'ASSIGNED NAME' },
      ],
      topSellingProductsList: [],
      headers: [
        { text: 'PRODUCT NAME', value: 'products', title: 'PRODUCT NAME' },
        { text: 'PRICE', value: 'products.unit_price', title: 'PRICE' },
        { text: 'QUANTITY', value: 'quantity_sold', title: 'QUANTITY' },
        { text: 'AMOUNT', value: 'sales_amount', title: 'AMOUNT' },
        { text: 'OUTLETS NAME', value: 'store_name', title: 'OUTLETS NAME' },
        { text: 'ASSIGNED AGENT', value: 'agent', title: 'ASSIGNED AGENT' },
      ],

      dates:[],
    };
  },
  created() {
    this.getTopSellingProduct();
  },
  computed: {

    filteredRoutes() {
      return this.topSellingProductsList.filter((sale) => {
        if (window.ResizeObserver) {
          const ro = new ResizeObserver(() => {
          });
          ro.observe(document.body);
        }
        const formatDate = (date, addOneDay = false) => {
          if (!date) return null;
          const d = new Date(date);
          if (isNaN(d.getTime())) return null;
          if (addOneDay) {
            d.setDate(d.getDate() + 1);
          }
          return d.toISOString().split('T')[0];
        };

        const startDate = this.dates.length ? formatDate(this.dates[0], true) : null;
        const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);

        const startDateObj = startDate ? new Date(startDate) : null;
        const endDateObj = endDate ? new Date(endDate) : null;

        const saleAssignDate = sale.created_at ? sale.created_at.split('T')[0] : null;
        const saleAssignDateObj = saleAssignDate ? new Date(saleAssignDate) : null;

        const withinDateRange =
            !startDateObj || !endDateObj ||
            (saleAssignDateObj &&
                saleAssignDateObj >= startDateObj &&
                saleAssignDateObj <= endDateObj);

        console.log('Within Date Range:', withinDateRange);
        console.log('Sale Date:', formatDate(sale.assign_date));
        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);

        return (
            withinDateRange
        );
      });
    },
  },

    methods:{

    clearDateRange() {
      this.dates = [];
    },

    getTopSellingProduct(){

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
     
      axios.post(`/api/sales/all/view`,
          { userId: userId,
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            this.topSellingProductsList = response.data.data;
            console.log('topsellingProduct:', this.topSellingProductsList);
           
          })
          .catch(error => {
            console.error('Error fetching sub categories:', error);
          });

    },
    exportExcel() {
      // Flattening the data for Excel export
      const tableData = this.topSellingProductsList.flatMap((item, index) =>
          item.products.map((product) => ({
            '#': index + 1, // Top-level index
            'PRODUCT NAME': product.product_name,
            'PRICE': product.unit_price,
            'QUANTITY': item.quantity_sold,
            'AMOUNT': item.sales_amount,
            'OUTLETS NAME': item.store_name,
            'ASSIGNED AGENT': item.agent ? item.agent.name : 'N/A', // Check if agent exists
          }))
      );

      // Creating a worksheet from the flattened data
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Creating a new workbook and appending the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Top Selling Products');

      // Writing the workbook to a file
      XLSX.writeFile(workbook, 'top_selling_products_analysis.xlsx');
    },
  }
};
</script>

<style scoped>
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}
.button-style {
  display: flex;
  justify-content: space-between;
}
.gray-text {
  color: #757575 !important;
}
.red-text {
  color: #BB0000;
}

.gray-background-text {
  background-color: #BDBDBD;
}
.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}
.data-table {
  width: 100%;
  height: 100%;
  border: black;
}

.product-column {
  display: flex;
}

.product-image {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  padding: 4px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  border-width: 4px;
  box-shadow: #757575;
  background-color: white;
  margin: 10px;
}

.product-title {
  white-space: pre-wrap;
  display: flex;
}

.outlet-column,
.agent-column {
  display: flex;
  flex-direction: column;
}

.outlet-secondary,
.agent-secondary {
  color: red;
}

</style>
