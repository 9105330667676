<template>
  <v-container fluid style="padding: 5px">
  <v-row class="main-heading">
    <v-col cols="12" md="11">
      <v-row>
        <v-card-title>NOT AVAILABLE AGENTS LIST</v-card-title>
      </v-row>
      <v-row>
        <v-card-subtitle>Analyse available agents data.</v-card-subtitle>
      </v-row>
    </v-col>
    <v-col cols="12" md="1">
      <v-btn class="back-button" @click="back()">BACK</v-btn>
    </v-col>
  </v-row>

  <v-row class="sub-heading">
    <v-card class="sub-heading-card d-flex align-center">
      <v-col cols="12" md="10">
        <v-card-subtitle class="his-sub-heading">SELLERS LIST</v-card-subtitle>
      </v-col>
      <v-col cols="12" md="1" class="col1">
        <v-btn class="red-light-text" variant="outlined" @click="exportPDF">
          <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="col2">
        <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
          <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
        </v-btn>
      </v-col>
    </v-card>
  </v-row>


  <v-card class="main-card " style="max-height: 500px; overflow-y: auto;">
    <v-row class="text-filed-row pa-0 ma-0">
      <v-col cols="12" md="12">
        <v-text-field
            class="text-black"
            label="Search"
            placeholder="Search"
            persistent-placeholder
            variant="outlined"
            v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="table-row">
      <v-col cols="12" class="table-col">
        <v-data-table
            :headers="headers"
            :items="activeAgents"
            :search="search"
            class="dataTable"
        >
          <template v-slot:[`item.action`]="{ item }" >
            <v-row >
              <v-switch
              color="red"
              label="Enable"
              :false-value="0"
              :true-value="1"
              hide-details
              class="btn-switch"
              v-model="item.active_status"
              @change="toggleEnable(item)"
              ></v-switch>
            </v-row>
            <v-row>
              <v-card-text class="action-text">ENABLE | Disable Tracking Permission </v-card-text>
            </v-row>
          </template>


          <template v-slot:[`item.name`]="{ item }" >
     
              <v-row class="custom-row">
                <p>{{ item.name }}</p>
                <p class="register-number">{{ item.registration_num }}</p>
              </v-row>
            
            </template>

          <template v-slot:[`item.states`]="{ item }" >
            <v-row >
              <v-chip
                  class="ma-2"
                  color="green"
                  variant="outlined"
                  label
                  v-if="item.active_status === 0"
              >
                Available
                <v-icon icon="mdi-check" end class="ml-4"></v-icon>
              </v-chip>

              <v-chip
                  class="ma-2"
                  color="orange"
                  variant="outlined"
                  label
                  v-if="item.active_status === 1"
              >
                Not Available
                <v-icon icon="mdi-close" end class="ml-5"></v-icon>
              </v-chip>

              <!-- <v-chip
                  class="ma-2"
                  color="red"
                  variant="outlined"
                  label
                  v-if="item.states === 'parking'"
              >
                PARKING
                <v-icon icon="mdi-check" end class="ml-3"></v-icon>
              </v-chip> -->
            </v-row>
          </template>

        </v-data-table>

      </v-col>
    </v-row>
  </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import axios from "axios";
import _ from "lodash"

export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'AGENT NAME', value: 'name', title: 'AGENT NAME' },
        { text: 'CURRENT ADDRESS', value: 'address', title:'CURRENT ADDRESS'},
        { text: 'DISTRICT', value: 'district', title:'DISTRICT' },
        { text: 'DSD', value: 'dsd', title:'DSD' },
        { text: 'STATUS', value: 'states', title: 'STATUS' },
        { text: 'Actions', value: 'action', sortable: false , title:'ACTION' },
      ],

      activeAgents:[],
      
    }
  },
  computed: {
    ...mapGetters({
      agentListDetails: 'getagentListDetails'
      
     }),

    },
  mounted(){
      // this.getActiveOfficers();
      this.getAllAgents();
    },
  methods: {
    back(){
      this.$router.push('/live-tracking');
    },

    toggleEnable(item) {

      console.log('item',item);

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 
      formData.append('agentId', item.id);
      formData.append('activeStatus', item.active_status); 

      axios.post('/api/updateTracking/activeStatus', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          // this.allOfficerDetails = response.data.ofiicerDetails;
          this.getAllAgents();

        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });

      },

      getAllAgents(){

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('userId', userId); 

        axios.post('/api/getAgentList', formData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          if (response.status === 200) {
          
            const agentList = response.data.data;
            console.log('fk', agentList);
            this.activeAgents = _.filter(agentList, (agent) => agent.active_status === 1);
            console.log('agents',this.agentListDetails)

          } else {
            console.error("Error:", response.data.error);
          }
        })
        .catch(error => {
          console.error("Error fetching chart details:", error);
        });
        },

    // getActiveOfficers(){

    // this.activeAgents = this.agentListDetails.filter(item => item.active_status == 1);
    // console.log('dadad',this.activeAgents);
    // },

    exportPDF() {
      const doc = new jsPDF();

      // Set title with improved styling
      doc.setFontSize(16);
      doc.text('Agent Report', 14, 20);

      // Subtitle or additional information
      doc.setFontSize(10);
      doc.text('Agent report with geofence details', 14, 28);

      // Define the columns with better alignment
      const tableColumns = [
        'Agent Name', 'Reg Num', 'Type', 'Email', 'NIC', 'Gender', 
        'Address', 'Province', 'District', 'DSD', 'GND', 
        'MyRoute', 'RoutePlan', 'SalesGeofence', 'CollectionGeofence'
      ];

      // Prepare data for the table
      const tableData = this.activeAgents.map((item) => [
        item.name,
        item.registration_num,
        item.user_type,
        item.email,
        item.nic,
        item.gender,
        item.address,
        item.province,
        item.district,
        item.dsd,
        item.gnd,
        item.my_route_geofence == 1 ? 'Active' : 'Inactive',
        item.route_plan_geofence == 1 ? 'Active' : 'Inactive',
        item.sales_geofence == 1 ? 'Active' : 'Inactive',
        item.collection_geofence == 1 ? 'Active' : 'Inactive'
      ]);

      
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
        startX: 35,
        theme: 'grid',
        headStyles: {
          fillColor: [40, 40, 200],
          textColor: [255, 255, 255],
          fontSize: 5,
          halign: 'center',
          valign: 'middle',
          cellPadding: 0.5,
        },
        bodyStyles: {
          fontSize: 5,
          textColor: [50, 50, 50],
          halign: 'center',
          valign: 'middle',
          cellPadding: 0.5,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        columnStyles: {
          0: { cellWidth: 12 },
          1: { cellWidth: 12 },
          2: { cellWidth: 12 },
          3: { cellWidth: 20 },
          4: { cellWidth: 12 },
          5: { cellWidth: 12 },
          6: { cellWidth: 12 },
          7: { cellWidth: 12 },
        },
        margin: { top: 10 },
      });

      // Footer with date
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

      // Save the PDF
      doc.save('All sAgents.pdf');
    },


    exportExcel() {
      // Prepare the data for Excel export
      const tableData = this.activeAgents.map((item) => ({
        'AGENT NAME': item.name,
        'REG NUM': item.registration_num,
        'TYPE': item.user_type,
        'EMAIL': item.email,
        'NIC': item.nic,
        'GENDER': item.gender,
        'ADDRESS': item.address,
        'PROVINCE': item.province,
        'DISTRICT': item.district,
        'DSD': item.dsd,
        'GND': item.gnd,
        'MY ROUTE PLAN': item.my_route_geofence == 1 ? 'Active' : 'Inactive',
        'ROUTE PLAN': item.route_plan_geofence == 1 ? 'Active' : 'Inactive',
        'SALES GEOFENCE': item.sales_geofence == 1 ? 'Active' : 'Inactive',
        'COLLECTION GEOFENCE': item.collection_geofence == 1 ? 'Active' : 'Inactive',
      }));

      // Create worksheet from JSON data
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set custom column widths
      worksheet['!cols'] = [
        { wch: 20 },  
        { wch: 15 },  
        { wch: 10 },  
        { wch: 25 },  
        { wch: 15 },  
        { wch: 10 },  
        { wch: 30 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 20 }, 
      ];

      // Freeze the header row
      worksheet['!freeze'] = { ySplit: 1 };

      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = {
          font: { bold: true },
          alignment: { horizontal: 'center' },
        };
      }

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'All Agents Reports');

      // Save the workbook as an Excel file
      XLSX.writeFile(workbook, 'All_Agents_Reports.xlsx');
    },
  
  },
};
</script>

<style scoped>

.main-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
  display: flex;
}

.back-button {
  margin-right: 30px;
  padding: 5px;
}

.sub-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
}

.sub-heading-card {
  width: 100vw;
  height: 100%;
  padding: 10px;
}

.red-light-text {
    background-color: #f1cccc;
    color: #b00d0d;
  }
 .red-text {
    color: #b00d0d;
  }

.green-light-text {
    background-color: #aee8b1;
    color: #057b0a;
  }
  .green-text {
    color: #057b0a;
    background-color: #aee8b1;
  }

.pdf-btn{
  width:60px;
  background-color: rgb(233, 196, 204);
  border: 1px solid red;
  margin-left: 30px;
}

.custom-row {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
}

.register-number {
  font-size: 14px;
  color: red; 
}

.exl-btn{
  width:60px;
  background-color: rgb(82, 180, 109);
  border: 1px solid rgb(16, 69, 13);
}

.text-filed-row{
  height:13vh;
  padding: 20px;
}
.table-col{
  height: 95%;
}

.action-text{
  font-size: 9px;
}
.data-table{
  height: 90%;
  border-radius: 10px;
  border: 1px solid rgb(227, 229, 231);
  margin: 20px;
}
.view-btn{
  background-color: rgb(140, 182, 249);
  border: 1px solid rgb(76, 76, 228);
  border-radius: 5px;
  color: rgb(76, 76, 228);
  margin-right: 20px;
}
.del-btn{
  background-color: rgb(233, 196, 204);
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  margin-right: 20px;
}



</style>



