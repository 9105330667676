<template >
    <v-card class="mt-3 main-card pl-3" flat >
      <v-row class="pl-2 pr-6">

        <v-col class="mt-3" cols="6">
          <h3 class="page-title">TOTAL SALES</h3>
          <h5 class="light-gray-text">Analyse sales data in a variety of stores.</h5>
          <v-card-title>
          </v-card-title>
        </v-col>

        <v-col class="px-4 mt-5" cols="6">
          <v-row class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>

      </v-row>
      <v-card class="px-2 pt-2 mx-1 mb-1 mx-1 leads-details-card" elevation="3" >
        <h5 class="card-h4-title px-3 mt-2 mb-4">SALES ANALYSIS
          <div class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
              <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
            </v-btn>

            <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
              <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </div>
        </h5>

        <v-divider class="pb-6"></v-divider>
        <v-data-table
            :headers="headers"
          :items="filteredRoutes"
          :items-per-page="5"
          :search="search"
          class="dataTable"
          style="padding-left: 25px; padding-right: 10px;padding-bottom: 10px"

      >
        <template v-slot:[`item.agent.name`]="{ item }">
          <div>
            <div>{{ item.agent.name}}</div>
            <div style="color: red">Reg num: {{ item.agent.registration_num }}</div>
          </div>
        </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <!-- Format and display only the date part -->
            {{ new Date(item.created_at).toISOString().split('T')[0] }}
          </template>

        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="4">
              <v-date-input
                  v-model="dates"
                  append-inner-icon="mdi-close"
                  color="red"
                  label="Select Date Range"
                  max-width="368"
                  multiple="range"
                  variant="outlined"
                  @click:append-inner="clearDateRange"
              ></v-date-input>
            </v-col>
                  <v-col cols="12" md="4">
                  <v-select
                    v-model="selectedAgent"
                    :items="agentLists"
                    clearable
                    item-title="name"
                    item-value="name"
                    label="Agent"
                    multiple
                    placeholder="Select agent name"
                    variant="outlined"
                  >
                  <!-- <template v-slot:item="{ item }">
                    <div>
                      <strong>{{ item.name }}</strong><br />
                      <small>{{ item.registration_num }}</small>
                    </div>
                  </template> -->
                  </v-select>
                </v-col>

              <v-col cols="12" md="4">
            <v-select
                    v-model="selectedRoute"
                    :items="getAllRouteName"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="Route"
                    multiple
                    placeholder="Select Route"
                    variant="outlined"
                  ></v-select>
              </v-col>


              <v-col cols="12" md="4">
            <v-select
                    v-model="selectDistrict"
                    :items="getAllDoneDistrict"
                    clearable
                    item-title="text" 
                    item-value="value"
                    label="District"
                    multiple
                    placeholder="Select District"
                    variant="outlined"
                  ></v-select>
              </v-col>

              <v-col cols="12" md="4">
            <v-select
                    v-model="selectDsd"
                    :items="getAllDsd"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="DSD"
                    multiple
                    placeholder="Select Dsd"
                    variant="outlined"
                  ></v-select>
              </v-col>

              <v-col cols="12" md="4">
            <v-select
                    v-model="selectGnd"
                    :items="getAllGnd"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="GND"
                    multiple
                    placeholder="Select Gnd"
                    variant="outlined"
                  ></v-select>
              </v-col>

              <v-col cols="12" md="4">
            <v-select
                    v-model="selectedSalesType"
                    :items="getAllSalesType"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="Sales Type"
                    multiple
                    placeholder="Select sub category"
                    variant="outlined"
                  ></v-select>
              </v-col>
              
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="search"
                    clearable
                    label="Search"
                    placeholder="Search"
                    variant="outlined"
                    width="100%"
                ></v-text-field>
            </v-col>
          </v-row>
        </template>


          <template v-slot:[`item.action`]="{ item }" >
            <v-row >
              <v-btn
                  v-if="permissions.read"
                  class="mr-3 bg-blue-lighten-5 viewbtn"
                  color="blue"
                  variant="outlined"
                  @click="viewItem(item)"
              >
                <v-icon>mdi-check-circle</v-icon>&nbsp;  view
              </v-btn>  
              <v-btn
                  v-if="permissions.write"
                  class="mr-3 bg-green-lighten-5 "
                  color="green"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  v-if="permissions.delete"
                  class="bg-red-lighten-5"
                  color="red"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="confirmDelete(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-row>
          </template>
      </v-data-table>
      </v-card>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
        <v-card class="pa-5">
          <v-row dense style="background-color: #d9d9d9;">
            <v-col cols="12" md="10">
              <v-card-title class="headline">DELETE SALES DATA</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end" cols="12" md="2">
              <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-4"></v-divider>
          <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this sales record ?</v-card-text>
          <v-card-text class="font-weight-medium">
            This sales record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
            <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="view_Dialog" class="solid-dialog" fullscreen persistent >
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row>
            <Sales_Record/>
          </v-row>
        </v-card>
      </v-dialog >



      <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent >
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row>
            <Sales_Record_Edit/>
          </v-row>
        </v-card>
      </v-dialog >

      <v-snackbar
          v-model="snackAlert.snackbar"
          :color="snackAlert.snackColor"
          :right="snackAlert.snackBarRight"
          :top="snackAlert.snackBarTop"
          timeout="3000"
      >
        {{ snackAlert.snackMessage }}

      </v-snackbar>

    </v-card>
</template>
<script>
import axios from 'axios';
import Sales_Record_Edit from "@/components/SALES_MANAGEMENT/Sales_Recod_Edit.vue";
import Sales_Record from "@/components/SALES_MANAGEMENT/Sales_Record.vue";
import { EventBus } from "@/plugins/event-bus";
import {mapActions} from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import _ from 'lodash';
import {getPermissions} from "@/plugins/permissions";

export default {
  components: { Sales_Record, Sales_Record_Edit },
  data() {
    return {
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      agentLists:[],
      selectedAgent:[],
      getAllDoneDistrict:[],
      getAllSalesType:[],
      getAllRouteName: [],
      getAllDsd:[],
      getAllGnd:[],
      selectDsd:[],
      selectGnd:[],
      selectDistrict:[],
      selectedSalesType:[],
      selectedRoute:[],
      menu: false,
      dates: [],
      dateRange: '',
      search: '',
      deleteDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      agentID: null,
      headers: [
        { text: 'Order Id', value: 'order_id', title: 'ORDER ID ' },
        { text: 'Outlet Name', value: 'store_name', title: 'OUTLET NAME' },
        { text: 'Agent Name', value: 'agent.name', title: 'AGENT NAME' },
        { text: 'Total Sales', value: 'sales_amount', title: 'TOTAL SALES' },
        { text: 'Route Name', value: 'route_name', title: 'ROUTE NAME' },
        { text: 'Sales Date', value: 'created_at', title: 'SALES DATE' },
        { text: 'Sales Type', value: 'sales_type', title: 'SALES TYPE' },
        { text: 'Actions', value: 'action', sortable: false, title: 'ACTION' },
      ],
      sales: [],
      permissions: {},
    }
  },

  methods: {
    ...mapActions(['updateSharedData']),

    clearDateRange() {
      this.dates = [];
    },

    // Get Agent List
    getAllAgentsDeteails() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`/api/agentUser`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {

            this.agentLists = response.data.data;
            console.log("API Response:", response.data.data);
            
            
          })
          .catch(error => {
            console.error("There was an error fetching the sales data!", error);
          });
    },


    fetchSalesData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/sales`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log("API Response:", response.data);
            this.sales = Array.isArray(response.data) ? response.data : [];

            console.log('fucking Data',this.sales);

            const selectableDistricts = _.groupBy(this.sales, 'district');
            const selectableDsd = _.groupBy(this.sales, 'dsd');
            const selectableGnd = _.groupBy(this.sales, 'gnd');
            const selectableRoute = _.groupBy(this.sales, 'route_name');
            const selectableType = _.groupBy(this.sales, 'sales_type');

            this.getAllDoneDistrict = Object.keys(selectableDistricts).map(district => ({
              text: district,
              value: district
            }));

            this.getAllRouteName = Object.keys(selectableRoute).map(route => ({
              text: route,
              value: route
            }));

            this.getAllSalesType = Object.keys(selectableType).map(type => ({
              text: type,
              value: type
            }));

            this.getAllDsd = Object.keys(selectableDsd).map(dsd => ({
              text: dsd,
              value: dsd
            }));

            this.getAllGnd = Object.keys(selectableGnd).map(gnd => ({
              text: gnd,
              value: gnd
            }));

            console.log("District", this.getAllDoneDistrict);

            console.log("Sales after assignment:", this.sales);
          })
          .catch(error => {
            console.error("There was an error fetching the sales data!", error);
            this.sales = [];
          });
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/sales/delete/${this.itemToDelete.id}`,{ userId },{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.sales = this.sales.filter(sale => sale.id !== this.itemToDelete.id);
            this.deleteDialog = false;
            this.itemToDelete = null;
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'success';
            this.snackAlert.snackMessage = 'Sales record was deleted successfully';
          })
          .catch(error => {
            console.error("There was an error deleting the sale!", error);
            this.snackAlert.snackbar = true;
            this.snackAlert.snackColor = 'error';
            this.snackAlert.snackMessage = 'Sales record was delete Error';
          });
    },
    updateDateRange() {
      if (this.dates.length === 2) {
        this.dateRange = `${this.dates[0]} - ${this.dates[1]}`;
      }
    },
    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Sales Analysis', 14, 10);

      // Prepare the data for the table based on filtered routes
      const tableData = this.filteredRoutes.map((item, index) => [
        index + 1,
        item.order_id,
        item.store_name,
        item.agent.name,
        item.sales_amount,
        item.route_name,
        item.created_at.split('T')[0],
        item.sales_type
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'Order ID', 'Outlet Name', 'Agent Name', 'Total Sales', 'Route Name', 'Sales Date', 'Sales Type'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('sales_analysis_filtered.pdf');
    },

    exportExcel() {

      const tableData = this.filteredRoutes.map((item, index) => ({
        '#': index + 1,
        'Order ID': item.order_id,
        'Route Name': item.route_name,
        'Outlet Name': item.store_name,
        'Agent Name': item.agent.name,
        'Total Sales': item.sales_amount,
        'Sales Date': item.created_at.split('T')[0],
        'Sales Type': item.sales_type,
        'Quantity Sold': item.quantity_sold,
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set column widths
      const columnWidths = [
        { wpx: 50 },
        { wpx: 150 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 80 },
        { wpx: 100 },
        { wpx: 80 },
        { wpx: 110 },
        { wpx: 80 },

      ];
      worksheet['!cols'] = columnWidths;

      // Style header cells
      Object.keys(worksheet).forEach(cell => {
        if (cell.startsWith('A1')) {
          worksheet[cell].s = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "1F4E78" } },
            alignment: { horizontal: "center" },
          };
        }
      });

      // Create workbook and append the sheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales Analysis');


      XLSX.writeFile(workbook, 'sales_analysis_filtered.xlsx');
    },


    viewItem(item) {
      // localStorage.removeItem('viewID');
      // localStorage.setItem('viewID', item.id);
      // EventBus.emit('viewAgentDetails', item);

      this.updateSharedData(item.id);
      this.view_Dialog = true;

    },
    editItem(item) {
      //  functionality to edit item details
      this.editDialog = true;
      this.updateSharedData(item.id);
    },
    closeD() {
      // localStorage.removeItem('viewID');
      this.view_Dialog = false;
      this.editDialog = false;
    },
    goBack() {
      this.$router.back();
    },
    SalesManagementButtonClick() {
      this.editDialog = true;
      this.view_Dialog = false;
    },
    sale2(){
      this.editDialog = false;
      this.view_Dialog = false;

    },
    setupEventListeners() {
      EventBus.on('SalesRecodeManagementButtonClick', this.SalesManagementButtonClick);
      EventBus.on('SalesRecodeManagementButtonClick2', this.sale2);

    },
    removeEventListeners() {
      EventBus.off('SalesRecodeManagementButtonClick', this.SalesManagementButtonClick);
      EventBus.off('SalesRecodeManagementButtonClick2', this.sale2);
    },
    backPageRoute(){
      this.$router.push('/dashboard');
    }
  },
  computed: {

    filteredRoutes() {
      return this.sales.filter((sale) => {
        if (window.ResizeObserver) {
          const ro = new ResizeObserver(() => {});
          ro.observe(document.body);
        }
        if (!sale) return false;

        const formatDate = (date, addOneDay = false) => {
          if (!date) return null;
          const d = new Date(date);
          if (isNaN(d.getTime())) return null;
          if (addOneDay) {
            d.setDate(d.getDate() + 1);
          }
          return d.toISOString().split('T')[0];
        };

        const startDate = this.dates.length ? formatDate(this.dates[0], true) : null;
        const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);

        const startDateObj = startDate ? new Date(startDate) : null;
        const endDateObj = endDate ? new Date(endDate) : null;

        const saleAssignDate = sale.created_at ? sale.created_at.split('T')[0] : null;
        const saleAssignDateObj = saleAssignDate ? new Date(saleAssignDate) : null;

        const withinDateRange =
            !startDateObj || !endDateObj ||
            (saleAssignDateObj &&
                saleAssignDateObj >= startDateObj &&
                saleAssignDateObj <= endDateObj);

        console.log('Within Date Range:', withinDateRange);
        console.log('Sale Date:', formatDate(sale.assign_date));
        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);

        // Other filters remain unchanged
        const matchesSearch =
            !this.search ||
            (sale.store_name && sale.store_name.includes(this.search)) ||
            (sale.agent && sale.agent.name && sale.agent.name.includes(this.search)) ||
            (sale.order_id && sale.order_id.toString().includes(this.search)) ||
            (sale.sales_amount && sale.sales_amount.toString().includes(this.search));

        const matchesAgent =
            !this.selectedAgent.length ||
            this.selectedAgent.includes(sale.agent.name);

        const matchesRoute =
            !this.selectedRoute.length ||
            this.selectedRoute.includes(sale.route_name);

        const matchesSalesType =
            !this.selectedSalesType.length ||
            this.selectedSalesType.includes(sale.sales_type);

        const matchesDistrict =
            !this.selectDistrict.length ||
            this.selectDistrict.includes(sale.district);

        const matchesDsd =
            !this.selectDsd.length ||
            this.selectDsd.includes(sale.dsd);

        const matchesGnd =
            !this.selectGnd.length ||
            this.selectGnd.includes(sale.gnd);

        return (
            withinDateRange &&
            matchesSearch &&
            matchesAgent &&
            matchesRoute &&
            matchesSalesType &&
            matchesDistrict &&
            matchesDsd &&
            matchesGnd
        );
      });
    }


  },
  mounted() {
    const dashboardId = 5;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    this.setupEventListeners();
    this.fetchSalesData();
    this.getAllAgentsDeteails();
    console.log('Headers:', this.headers);
    console.log('Sales:', this.sales);
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  watch: {
    dates() {
      this.updateDateRange();
    }
  },
}
</script>

<style scoped>

.main-card{
  padding-right: 20px;
  width:100%;
  height: 100%;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}


:deep(.dataTable) th, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
  max-width: 12vw;
  justify-items: center;
  
}
:deep(.dataTable) th:last-child {
  width: 18vw;
  max-width: 20vw;
}
:deep(.dataTable) td:last-child {
  width: 18vw;
  max-width: 20vw;
  padding-top: 10px;
  margin-top: 10px;
}

:deep(.dataTable) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  max-width: 12vw;
  justify-content: space-between;
}

:deep(.dataTable) {
  border: white;
}

:deep(.dataTable )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

:deep(.dataTable )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

.v-dialog > .v-overlay__content > .v-card, .v-dialog > .v-overlay__content > .v-sheet, .v-dialog > .v-overlay__content > form > .v-card, .v-dialog > .v-overlay__content > form > .v-sheet {
  overflow-y: hidden;
}

.leads-details-card{
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll !important;
  max-height: 79vh;

}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}

.red-text {
  color: #b00d0d;
}
.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}
.red-white-text {
  background-color: #b00d0d;
  color: white;
}
.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}


</style>