<template>
  <v-card class="mt-3 card-size" flat>
    <div style="max-height: 100vh; overflow-y: auto;">
      <v-row class="pl-3 pr-6">
        <v-col class="mt-3" cols="6">
          <h3 class="page-title">LOGS DATA</h3>
          <h5 class="light-gray-text">Analyse all logs data using a variety of parameters.</h5>
        </v-col>
        <v-col class="px-4 mt-5" cols="6">
          <v-row class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="px-2 pt-2 mb-3 pb-2 mx-1 leads-details-card" elevation="3">
        <h5 class="card-h4-title px-3 mt-2 mb-4">LOGS DATA
          <div class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
              <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
            </v-btn>
            <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
              <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </div>
        </h5>

        <v-divider class="pb-6"></v-divider>

        <v-row>
          <v-col cols="4">
            <v-select
                v-model="selectedArea"
                :items="areaOptions"
                chips
                clearable
                item-title="text"
                item-value="value"
                label="Management Area"
                multiple
                placeholder="Select area"
                variant="outlined"
            ></v-select>
          </v-col>

          <v-col cols="4">
            <v-date-input
                v-model="dates"
                append-inner-icon="mdi-close"
                color="red"
                label="Select Date Range"
                max-width="500"
                multiple="range"
                variant="outlined"
                @click:append-inner="clearDateRange"
            ></v-date-input>
          </v-col>

          <v-col cols="4">
            <v-select
                v-model="selectedActions"
                :items="filterActions"
                chips
                clearable
                item-title="text"
                item-value="value"
                label="Action"
                multiple
                placeholder="Select area"
                variant="outlined"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-select
                v-model="selectedUsers"
                :items="filterUsers"
                chips
                clearable
                item-title="text"
                item-value="value"
                label="User"
                multiple
                placeholder="Select area"
                variant="outlined"
            ></v-select>
          </v-col>

          <v-col cols="4">
            <v-select
                v-model="selectedUserRoles"
                :items="userRoles"
                chips
                clearable
                item-title="text"
                item-value="value"
                label="User Role"
                multiple
                placeholder="Select area"
                variant="outlined"
            ></v-select>
          </v-col>

          <v-col cols="4">
            <v-text-field
                v-model="search"
                append-inner-icon="mdi-magnify"
                clearable
                hide-details
                label="Search"
                placeholder="Search data"
                variant="outlined"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-col cols="12">
          <div style="max-height: 500px; overflow-y: auto;">
            <v-data-table
                v-model:page="page"
                :headers="headersUserLog"
                :items="filteredUserLogs"
                :items-per-page="itemsPerPage"
                class="data-table elevation-1"
                @page="fetchUserLog"
                @page-count="totalPages = $event"
            >
              <template v-slot:[`item.description`]="{ item }">
                  <span class="blue-text description-column">
                    {{ item.description }}
                  </span>
              </template>
            </v-data-table>
          </div>
          <v-row>
            <v-col class="text-right" cols="12">
              <v-pagination
                  v-model="page"
                  :length="totalPages"
                  @input="fetchUserLog"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>

    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :right="snackAlert.snackBarRight"
        :top="snackAlert.snackBarTop"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}
    </v-snackbar>
  </v-card>
</template>


<script>
import axios from 'axios';
// import _ from 'lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";

export default {
  components: {},
  name: 'UserLog',
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      totalPages: 0,
      areaOptions: [], // Renamed from managementAreaDashboards
      selectedArea: [],// Renamed from filters.managementArea
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      filters: {
        managementArea: [],
        action: [],
        users: [],
        userType: [],
        dates: [],
        search: '',
      },
      dates:[],
      managementAreaDashboards: [],
      filterActions: [],
      selectedActions: [],
      selectedUsers: [],
      selectedUserRoles: [],
      filterUsers: [],
      userRoles: [],
      search: '',

      dateRange: '',

      headersUserLog: [
        {text: 'DESCRIPTION', value: 'description', title: 'DESCRIPTION', align: 'start', sortable: false},
        {text: 'ACTION TIME & DATE', value: 'action_date_time', title: 'ACTION TIME & DATE'},
        {text: 'ACTION', value: 'action_type', title: 'ACTION'},
        {text: 'USER ID', value: 'user_id', title: 'USER ID'},
        {text: 'USER NAME', value: 'user_name', title: 'USER NAME'},
        {text: 'IP ADDRESS', value: 'ip_address', title: 'IP ADDRESS'},
        {text: 'ROLE', value: 'user_role', title: 'ROLE'}
      ],
      filteredLogs: [],
      fetchDataintoTable: [], // This will hold the filtered data.
    }
  },

  watch: {
    'filters.managementArea': {
      handler() {
        this.fetchUserLog(); // Fetch data whenever managementArea changes
      },
      immediate: true,
    },
    'filters.dates': function () {
      this.updateDateRange();
    },
    'filters.action': function () {
      this.fetchUserLog(); // Fetch data whenever action changes
    },
    'filters.users': function () {
      this.fetchUserLog(); // Fetch data whenever users change
    },
    'filters.userType': function () {
      this.fetchUserLog(); // Fetch data whenever userType changes
    },
    'filters.search': function () {
      this.fetchUserLog(); // Fetch data whenever search term changes
    },
  },

  computed: {
    filteredUserLogs() {
      return this.filteredLogs.filter((logs) => {
        if (window.ResizeObserver) {
          const ro = new ResizeObserver(() => {});
          ro.observe(document.body);
        }

        const formatDate = (date, adjust = false) => {
          const d = new Date(date);
          if (adjust) {
            // Add one day (24 hours) only if adjust is true
            d.setDate(d.getDate() + 1);
          }
          return d.toISOString().split('T')[0];
        };

        const startDate = this.dates.length ? formatDate(this.dates[0], true) : null;
        const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;


        console.log("startDate",startDate );
        console.log("endDate",endDate );

        const withinDateRange =
            !startDate ||
            !endDate ||
            (logs.action_date_time &&
                formatDate(logs.action_date_time) >= startDate &&
                formatDate(logs.action_date_time) <= endDate);

        console.log("withinDateRange",withinDateRange );

        const matchesManagementArea=
            !this.selectedArea.length ||
            this.selectedArea.includes(logs.entity_area);

        const matchesUsers =
            !this.selectedUsers.length ||
            this.selectedUsers.includes(logs.user_name);

        const matchesUserRoles =
            !this.selectedUserRoles.length ||
            this.selectedUserRoles.includes(logs.user_role);

        const matchesAction =
            !this.selectedActions.length ||
            this.selectedActions.includes(logs.action_type);

        const matchesSearch =
            !this.search ||
            (logs.entity_area && logs.entity_area.toLowerCase().includes(this.search.toLowerCase())) ||
            (logs.description && logs.description.toLowerCase().includes(this.search.toLowerCase())) ||
            (logs.id && logs.id.toString().includes(this.search)) ||
            (logs.user_name && logs.user_name.toLowerCase().includes(this.search.toLowerCase())) ||
            (logs.user_role && logs.user_role.toString().includes(this.search)) ||
            (logs.action_type && logs.action_type.toString().includes(this.search));

        return withinDateRange && matchesManagementArea && matchesUsers && matchesUserRoles && matchesAction && matchesSearch;
      });
    },
  },
  mounted() {
    // this.getAllUserRoles();
    this.getDashboardPermissions();
    // this.getUsers();
    //   this.fetchUserLog();
  },
  methods: {
    clearDateRange() {
      this.dates = [];
    },
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },
    backPageRoute() {
      this.$router.back();
    },

    updateDateRange() {
      if (this.filters.dates.length === 2) {
        this.dateRange = `${this.filters.dates[0]} - ${this.filters.dates[1]}`;
        this.fetchUserLog(); // Fetch filtered data
      }
    },


    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/user-log-table-names`, {userId}, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
        }
      })
          .then(response => {
            const dashboards = response.data;
            console.log('Dashboards:', dashboards);

            // Map the response to format for v-select
            const dashboardMapping = {
              "dashboard": "Dashboard",
              "collection_user_logs": "Collection Management",
              "lead_user_log": "Leads Management",
              "outlet_user_log": "Outlet Management",
              "product_user_log": "Product Management",
              "route_user_log": "Route Management",
              "sales_user_logs": "Sales Management",
              "tracking_trip_user_log": "Tracking Management",
              "user_registration_log": "User Management",
              "user_role_user_log": "User Log"
            };

            this.managementAreaDashboards = dashboards.map(item => ({
              text: dashboardMapping[item] || item, // Use the mapped value or fallback to the original
              value: item
            }));

            console.log('Dashboard permissions:', this.managementAreaDashboards);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },
    fetchUserLog() {
      const token = localStorage.getItem("token");

      axios.get(`/api/audit-logs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            // Save the logs data into a reactive property
            this.filteredLogs = response.data; // Adjust based on response structure
            console.log('Result', response.data);


            // Extract unique entity_area values for the v-select dropdown
            // Assign the values to areaOptions
            this.areaOptions = [...new Set(this.filteredLogs.map(log => log.entity_area))]
                .map(area => ({text: area, value: area}));

            this.filterActions = [...new Set(this.filteredLogs.map(log => log.action_type))]
                .map(area => ({text: area, value: area}));

            this.filterUsers = [...new Set(this.filteredLogs.map(log => log.user_name))]
                .map(area => ({text: area, value: area}));

            this.userRoles = [...new Set(this.filteredLogs.map(log => log.user_role))]
                .map(area => ({text: area, value: area}));

            console.log('2');
            console.log('Area ', this.areaOptions);
            console.log('Action ', this.filterActions);
          })
          .catch(error => {
            console.error('Error fetching user logs:', error);
          });
    },

    exportExcel() {
      const MAX_CHAR_LIMIT = 32767;
      const tableData = this.filteredUserLogs.map((item, index) => ({
        '#': index + 1,
        'DESCRIPTION': item.description?.substring(0, MAX_CHAR_LIMIT),
        'ACTION TIME & DATE': item.action_date_time,
        'ACTION': item.action_type,
        'Management Area': item.entity_area,
        'New Value': item.new_values?.substring(0, MAX_CHAR_LIMIT),
        'Old Value': item.old_values?.substring(0, MAX_CHAR_LIMIT),
        'USER ID': item.user_id,
        'USER NAME': item.user_name,
        'USER ROLE': item.user_role,
        'IP ADDRESS': item.ip_address,
        'Created At': item.created_at,
        'Updated At': item.updated_at,
      }));

      // Create worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'UserLog');

      // Set column widths for readability
      const columnWidths = [
        { wch: 5 },    // "#"
        { wch: 50 },   // "DESCRIPTION"
        { wch: 25 },   // "ACTION TIME & DATE"
        { wch: 15 },   // "ACTION"
        { wch: 20 },   // "Management Area"
        { wch: 50 },   // "New Value"
        { wch: 50 },   // "Old Value"
        { wch: 10 },   // "USER ID"
        { wch: 20 },   // "USER NAME"
        { wch: 15 },   // "USER ROLE"
        { wch: 15 },   // "IP ADDRESS"
        { wch: 20 },   // "Created At"
        { wch: 20 },   // "Updated At"
      ];
      worksheet['!cols'] = columnWidths;

      // Optional: Apply bold header style if using xlsx-style
      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = {
            font: { bold: true },
            alignment: { vertical: 'center', horizontal: 'center' },
          };
        }
      }

      // Write to file
      XLSX.writeFile(workbook, 'user_log.xlsx');
    },


    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('User Log', 14, 10);

      const tableData = this.filteredUserLogs.map((item, index) => [
        index + 1,
        item.description,
        item.action_date_time,
        item.action_type,
        item.user_id,
        item.user_name,
        item.user_role,

      ]);

      // Define the columns for the table
      // const tableColumns = ['#', 'DESCRIPTION', 'ACTION TIME & DATE', 'ACTION', 'USER ID', 'USER NAME', 'IP ADDRESS', 'ROLE'];
      const tableColumns = ['#', 'DESCRIPTION', 'ACTION TIME & DATE', 'ACTION', 'USER ID', 'USER NAME','USER ROLE'];
      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('user_log.pdf');

    },
  }

};
</script>

<style scoped>
.blue-text {
  color: blue !important;
}

.card-size {
  width: 100% !important;
  padding: 0;
  margin-bottom: 0;
}

.page-title {
  font-weight: bold;
}

.light-gray-text {
  color: #a09e9e !important;
}

.tool-bar-style {
  display: flex;
  justify-content: end;
}

.divider-vertical {
  color: #3c3b3b !important;
}

.gray-text {
  color: #757575 !important;
}

.leads-details-card {
  height: 100%;
  overflow: auto;
  margin-bottom: 20px;
}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}

.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}

.red-white-text {
  background-color: #b00d0d;
  color: white;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}

.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}

.data-table {
  width: 100% !important;
  border: black;
}

.description-column {
  max-width: 210px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allows text to wrap to the next line */
  word-wrap: break-word; /* Breaks long words to wrap them to the next line */
}
</style>