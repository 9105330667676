<template>
  <v-card class="mt-3 main-card pl-3" variant="flat" >
    <v-row class="pl-2 pr-6">

      <v-col class="mt-3" cols="6">
        <h3 class="page-title">ALL AGENTS LIST</h3>
        <h5 class="light-gray-text">Analyse all agents data.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>

      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>

    </v-row>
    <v-card class="px-2 pt-2 mb-3 pb-2 mx-1 leads-details-card" elevation="3" >
      <h5 class="card-h4-title px-3 mt-2 mb-4">AGENT LIST
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>

          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>

      <v-divider class="pb-6"></v-divider>

    <v-row class="table-row">
      <v-col class="table-col" cols="12">
        <v-data-table
            :headers="headers"
            :items="agentListDetails"
            :search="search"
            class="data-table"
        >

          <template v-slot:top>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                    v-model="search"
                    class="text-black"
                    label="Search"
                    persistent-placeholder
                    placeholder="Search"
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
         

        <template v-slot:[`item.action`]="{ item }">
          <v-row>
            <v-switch
              v-model="item.active_status"
              :false-value="0"
              :true-value="1"
              class="btn-switch"
              color="red"
              hide-details
              label="Enable"
              @change="toggleEnable(item)"
            ></v-switch>
          </v-row>
          <v-row>
            <v-card-text class="action-text">ENABLE | Disable Tracking Permission</v-card-text>
          </v-row>
        </template>

          <template v-slot:[`item.name`]="{ item }" >
     
            <v-row class="custom-row">
              <p>{{ item.name }}</p>
              <p class="register-number">{{ item.registration_num }}</p>
            </v-row>
          
          </template>


          <template v-slot:[`item.states`]="{ item }" >
            <v-row >
              <v-chip
                  v-if="item.active_status === 0"
                  class="ma-2"
                  color="green"
                  label
                  variant="outlined"
              >
                Available
                <v-icon class="ml-4" end icon="mdi-check"></v-icon>
              </v-chip>

              <v-chip
                  v-if="item.active_status === 1"
                  class="ma-2"
                  color="orange"
                  label
                  variant="outlined"
              >
                Not Available
                <v-icon class="ml-5" end icon="mdi-close"></v-icon>
              </v-chip>

              <!-- <v-chip
                  class="ma-2"
                  color="red"
                  variant="outlined"
                  label
                  v-if="item.states === 'parking'"
              >
                PARKING
                <v-icon icon="mdi-check" end class="ml-3"></v-icon>
              </v-chip> -->
            </v-row>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
  </v-card>
</template>

<script>
// import { mapGetters } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import axios from "axios";
export default {
  name: "AllAgents",
  data() {
    return {
      search: '',
      headers: [
        { text: 'AGENT NAME', value: 'name', title: 'AGENT NAME' },
        { text: 'CURRENT ADDRESS', value: 'address', title:'CURRENT ADDRESS'},
        { text: 'DISTRICT', value: 'district', title:'DISTRICT' },
        { text: 'DSD', value: 'dsd', title:'DSD' },
        { text: 'STATUS', value: 'states', title: 'STATUS' },
        { text: 'Actions', value: 'action', sortable: false , title:'ACTION' },
      ],
      
      agentListDetails:[],
      
    }
  },
  computed: {
    // ...mapGetters({
    //   agentListDetails: 'getagentListDetails'
      
      
    //  }),

    }, 

  created(){
    
    this.getAllAgents();
  }, 
  methods: {

    toggleEnable(item) {

      console.log('item',item);
     
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 
      formData.append('agentId', item.id);
      formData.append('activeStatus', item.active_status); 
      
      axios.post('/api/updateTracking/activeStatus', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          // this.allOfficerDetails = response.data.ofiicerDetails;
          this.getAllAgents();

        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
     
    },

    getAllAgents(){

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('userId', userId); 
     
      axios.post('/api/getAgentList', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.status === 200) {
        
          this.agentListDetails = response.data.data;
          console.log('agents',this.agentListDetails)

        } else {
          console.error("Error:", response.data.error);
        }
      })
      .catch(error => {
        console.error("Error fetching chart details:", error);
      });
    },
    backPageRoute() {
      this.$router.push('/dashboard');
    },
  
    back(){
      this.$router.push('/live-tracking');
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set title with improved styling
      doc.setFontSize(16);
      doc.text('Agent Report', 14, 20);

      // Subtitle or additional information
      doc.setFontSize(10);
      doc.text('Agent report with geofence details', 14, 28);

      // Define the columns with better alignment
      const tableColumns = [
        'Agent Name', 'Reg Num', 'Type', 'Email', 'NIC', 'Gender', 
        'Address', 'Province', 'District', 'DSD', 'GND', 
        'MyRoute', 'RoutePlan', 'SalesGeofence', 'CollectionGeofence'
      ];

      // Prepare data for the table
      const tableData = this.agentListDetails.map((item) => [
        item.name,
        item.registration_num,
        item.user_type,
        item.email,
        item.nic,
        item.gender,
        item.address,
        item.province,
        item.district,
        item.dsd,
        item.gnd,
        item.my_route_geofence == 1 ? 'Active' : 'Inactive',
        item.route_plan_geofence == 1 ? 'Active' : 'Inactive',
        item.sales_geofence == 1 ? 'Active' : 'Inactive',
        item.collection_geofence == 1 ? 'Active' : 'Inactive'
      ]);

      
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
        startX: 35,
        theme: 'grid',
        headStyles: {
          fillColor: [40, 40, 200],
          textColor: [255, 255, 255],
          fontSize: 5,
          halign: 'center',
          valign: 'middle',
          cellPadding: 0.5,
        },
        bodyStyles: {
          fontSize: 5,
          textColor: [50, 50, 50],
          halign: 'center',
          valign: 'middle',
          cellPadding: 0.5,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        columnStyles: {
          0: { cellWidth: 12 },
          1: { cellWidth: 12 },
          2: { cellWidth: 12 },
          3: { cellWidth: 20 },
          4: { cellWidth: 12 },
          5: { cellWidth: 12 },
          6: { cellWidth: 12 },
          7: { cellWidth: 12 },
        },
        margin: { top: 10 },
      });

      // Footer with date
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

      // Save the PDF
      doc.save('All Agents.pdf');
    },


    exportExcel() {
      // Prepare the data for Excel export
      const tableData = this.agentListDetails.map((item) => ({
        'AGENT NAME': item.name,
        'REG NUM': item.registration_num,
        'TYPE': item.user_type,
        'EMAIL': item.email,
        'NIC': item.nic,
        'GENDER': item.gender,
        'ADDRESS': item.address,
        'PROVINCE': item.province,
        'DISTRICT': item.district,
        'DSD': item.dsd,
        'GND': item.gnd,
        'MY ROUTE PLAN': item.my_route_geofence == 1 ? 'Active' : 'Inactive',
        'ROUTE PLAN': item.route_plan_geofence == 1 ? 'Active' : 'Inactive',
        'SALES GEOFENCE': item.sales_geofence == 1 ? 'Active' : 'Inactive',
        'COLLECTION GEOFENCE': item.collection_geofence == 1 ? 'Active' : 'Inactive',
      }));

      // Create worksheet from JSON data
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set custom column widths
      worksheet['!cols'] = [
        { wch: 20 },  
        { wch: 15 },  
        { wch: 10 },  
        { wch: 25 },  
        { wch: 15 },  
        { wch: 10 },  
        { wch: 30 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 15 },  
        { wch: 20 }, 
      ];

      // Freeze the header row
      worksheet['!freeze'] = { ySplit: 1 };

      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = {
          font: { bold: true },
          alignment: { horizontal: 'center' },
        };
      }

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'All Agents Reports');

      // Save the workbook as an Excel file
      XLSX.writeFile(workbook, 'All_Agents_Reports.xlsx');
    },

  },
};
</script>

<style scoped>

.main-card {
  width: 100%;
  height: 100%;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}

:deep(.data-table) th, td {
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
}

:deep(.data-table) td, td {
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.data-table) {
  border: white;
  overflow: hidden !important;
}

:deep(.data-table ) tr, th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

:deep(.data-table ) tr, td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

.v-dialog > .v-overlay__content > .v-card, .v-dialog > .v-overlay__content > .v-sheet, .v-dialog > .v-overlay__content > form > .v-card, .v-dialog > .v-overlay__content > form > .v-sheet {
  overflow-y: hidden;
}


.leads-details-card{
  height: 100%;
  padding-bottom: 20px;
  margin-bottom: 10px;
  max-height: 80vh;
  overflow-y: scroll;
}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}

.red-text {
  color: #b00d0d;
}
.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}
.red-white-text {
  background-color: #b00d0d;
  color: white;
}
.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}

</style>


<script lang="ts" setup>
</script>