<template>

  <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
      <v-progress-circular
        color="green"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-card class="mt-3 card-size pl-3" flat>
      <v-row class="pl-2 pr-6">
        <v-col class="mt-3" cols="7">
          <h3 class="page-title">CREATE PRODUCTS CATEGORY</h3>
          <h5 class="light-gray-text">Create a new product category according to your requirements.</h5>
          <v-card-title>
            
          </v-card-title>
        </v-col>
        <v-col class="px-4 mt-5" cols="5">
          <v-row class="tool-bar-style">
              <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
              <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
                <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
              </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-card :variant="tonal" class="px-2 pt-2 mx-1  product-details-card" style="max-height: 500px; overflow-y: scroll; scrollbar-width: none; -ms-overflow-style: none;">
        <h5 class="card-two-title px-3 mt-2 mb-4">CREATE NEW PRODUCT CATEGORIES</h5>
        <v-divider></v-divider>

        <v-row class="pt-6">
          <v-col class="px-6" cols="6">
            <v-card class="card-hight-fixed pr-6" flat>
              <!-- Main Category Input -->
              <v-text-field
                  v-model="mainCategory"
                  :rules="[v => !!v || 'Main Category is required']"
                  class="my-2"
                  label="Main Category"
                  placeholder="Create main category"
                  variant="outlined"
              ></v-text-field>

              <!-- Main Category Image Upload Area -->
              <v-card flat style="height:auto; margin-bottom: 10px">
                <div
                    class="drop-area"
                    @click="triggerFileInput"
                    @drop.prevent="onDrop"
                    @dragover.prevent="onDragOver"
                >
                  <input
                      ref="fileInput"
                      accept="image/*"
                      class="hidden-file-input"
                      type="file"
                      @change="onFileChange"
                  />
                  <div
                      v-if="previewImage"
                      class="image-card-container"
                  >
                    <v-card class="image-card">
                      <v-img
                          :alt="'Image preview'"
                          :src="previewImage"
                          class="image-preview"
                      ></v-img>
                      <v-btn
                          class="remove-icon"
                          icon
                          size="x-small"
                          @click.stop="removeImage"
                      >
                        <v-icon color="red" size="small">mdi-delete</v-icon>
                      </v-btn>
                    </v-card>
                  </div>

                  <!-- Placeholder for Image Upload -->
                  <div
                      v-if="!previewImage"
                      class="placeholder-container"
                      style="height: 150px; background-color: #f0f0f0;"
                  >
                    <v-icon size="36">mdi-cloud-upload</v-icon>
                    <span>Drag your photo here OR click to browse from device</span>
                  </div>
                </div>
              </v-card>



              <!-- Sub Category Input and Add/Remove Buttons -->
              <div v-for="(subCategory, index) in subCategories" :key="index">
                <v-row class="mb-2">
                  <v-col cols="8">
                    <v-text-field
                        v-model="subCategories[index]"
                        label="Sub Category"
                        placeholder="Create sub category"
                        variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <!-- Add/Remove Buttons -->
                  <v-col class="align-center justify-center" cols="2">
                    <v-btn
                        class="green-text"
                        density="default"
                        icon="mdi-plus-thick"
                        variant="outlined"
                        @click="addSubCategory"
                    ></v-btn>
                  </v-col>
                  <v-col class="align-center justify-center" cols="2">
                    <v-btn
                        v-if="subCategories.length > 1"
                        class="red-text-btn"
                        density="default"
                        icon="mdi-minus-thick"
                        variant="outlined"
                        @click="removeSubCategory(index)"
                    ></v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>

          <!-- Category Description -->
          <v-col class="d-flex flex-column justify-end" cols="6">
        

            <v-card flat width="100%">
                <div class="d-flex justify-space-between pa-4 pb-0">
                  <!-- Text Formatting Buttons -->
                  <v-btn-toggle v-model="formatting" divided multiple variant="outlined">
                    <v-btn @click="applyFormat('italic')">
                      <v-icon icon="mdi-format-italic"></v-icon>
                    </v-btn>

                    <v-btn @click="applyFormat('bold')">
                      <v-icon icon="mdi-format-bold"></v-icon>
                    </v-btn>

                    <v-btn @click="applyFormat('underline')">
                      <v-icon icon="mdi-format-underline"></v-icon>
                    </v-btn>

                    <!-- Text Color Option -->
                    <v-menu
                        v-model="showColorPicker"
                        close-on-content-click
                        location="end"
                    >

                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">
                          <div class="d-flex align-center flex-column justify-center">
                            <v-icon icon="mdi-format-color-text"></v-icon>
                            <v-sheet :color="selectedColor" height="4" tile width="26"></v-sheet>
                          </div>
                        </v-btn>
                      </template>

                      <!-- Color Picker -->
                      <v-color-picker
                          v-model="selectedColor"
                          flat
                          hide-canvas
                          @change="applyFormat('color')"
                      ></v-color-picker>
                    </v-menu>
                  </v-btn-toggle>

                  <!-- Text Alignment Buttons -->
                  <v-btn-toggle v-model="alignment" divided variant="outlined">
                    <v-btn @click="setAlignment('center')">
                      <v-icon icon="mdi-format-align-center"></v-icon>
                    </v-btn>

                    <v-btn @click="setAlignment('left')">
                      <v-icon icon="mdi-format-align-left"></v-icon>
                    </v-btn>

                    <v-btn @click="setAlignment('right')">
                      <v-icon icon="mdi-format-align-right"></v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>

                <!-- Text Area -->
                <v-sheet class="pa-4 text-center">
                  <!-- <v-textarea
                      v-model="lead.description"
                      class="mb-2"
                      label="Itineraries Description *"
                      placeholder="Write a description here..."
                      :style="textareaStyles"
                      variant="outlined"
                      :rules="[v => !!v || 'Description is required']"
                  ></v-textarea> -->
                  <v-textarea
                  v-model="categoryDescription"
                  :rules="[v => !!v || 'Category description is required']"
                  :style="textareaStyles"
                  class="mb-2"
                  label="Category Description"
                  placeholder="Write a description here..."
                  variant="outlined"
                  ></v-textarea>
                </v-sheet>
              </v-card>
          </v-col>
        </v-row>


        <v-divider class="divider-two-style"></v-divider>
        <v-row class="margin-bottomn-fix pa-3 ma-3">
            <v-spacer></v-spacer>
            <v-btn class="red-background-text" size="large" variant="outlined" @click="createCategory">CREATE CATEGORY</v-btn>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <v-btn class="gray-background-text" size="large" variant="outlined" @click="cancel()">CANCEL</v-btn>
        </v-row>    
      </v-card>

      <!-- Snackbar to show notifications -->
      <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :right="snackAlert.snackBarRight"
        :top="snackAlert.snackBarTop"
        timeout="3000"
      >
        {{ snackAlert.snackMessage }}
        
      </v-snackbar>
    </v-card>
</template>

<script>
import axios from 'axios';
    export default {
      name: 'addCategory',
      data() {
        return {
        centerLoading:false,
        formatting: [],
        showColorPicker: false,
        selectedColor: '#000',
        alignment: '',
        textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
        },
          images: [],
          previewImage: null,
          mainCategory: '',
          subCategories: [''],
          categoryDescription: '',
          snackAlert: {
            snackbar: false, // Snackbar visibility
            snackColor: "", // Snackbar color
            snackMessage: "", // Snackbar message
            snackBarRight: false, // Snackbar position right
            snackBarTop: true,  // Snackbar position top
          },
        };
      },
      methods: {
        applyFormat(format) {

          if (format === 'italic') {
            this.textareaStyles.fontStyle =
                this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
          } else if (format === 'bold') {
            this.textareaStyles.fontWeight =
                this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
          } else if (format === 'underline') {
            this.textareaStyles.textDecoration =
                this.textareaStyles.textDecoration === 'underline'
                    ? 'none'
                    : 'underline';
          } else if (format === 'color') {
            this.textareaStyles.color = this.selectedColor;
          }
          },

          setAlignment(align) {
          this.textareaStyles.textAlign = align;
          },
        backPageRoute(){
            this.$router.back();
        },
        addSubCategory() {
            this.subCategories.push('');
        },
        removeSubCategory(index) {
            this.subCategories.splice(index, 1);
        },

        sizeValidation(value) {
          const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
          return sizePattern.test(value.trim());
        },
        triggerFileInput() {
          this.$refs.fileInput.click();
        },
        onFileChange(event) {
          const file = event.target.files[0]; // Get the first file from the input
          if (file) {
            this.previewImage = URL.createObjectURL(file); // Set the preview image
            this.handleFiles(file); // Pass the file to handleFiles
          }
        },
        onDrop(event) {
          const file = event.dataTransfer.files[0]; // Get the first file from the drop
          if (file) {
            this.previewImage = URL.createObjectURL(file); // Set the preview image
            this.handleFiles(file); // Pass the file to handleFiles
          }
        },
        removeImage() {
          this.previewImage = null;
          this.$refs.fileInput.value = null;
        },

        showSnackbar(message, color) {
          this.snackAlert.snackMessage = message;
          this.snackAlert.snackColor = color;
          this.snackAlert.snackbar = true; // Show the snackbar
        },

        // Add the rest of your methods here, including createCategory and validation

        validateForm() {
          if (!this.mainCategory) {
            this.showSnackbar('Main category is required.', 'error');
            return false;
          }

          if (!this.categoryDescription) {
            this.showSnackbar('Category description is required.', 'error');
            return false;
          }

          return true;
        },
        clearForm() {
          this.mainCategory = ''; // Clear the main category field
          this.categoryDescription = ''; // Clear the description field
          this.image = null; // Clear the selected image
          this.previewImage = null; // Clear the preview image
          this.$refs.fileInput.value = null; // Reset the file input
        },

        async createCategory() {
          // Validate form before sending the request
          if (!this.validateForm()) {
            return;
          }

          this.centerLoading = true;
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');

          const formattedSubCategories = this.subCategories.map(subCategory => ({
            subcatagaryName: subCategory
          }));

          const formData = new FormData();
          formData.append('userId', userId);
          formData.append('mainCatagary', this.mainCategory);
          formData.append('subCatagary', JSON.stringify(formattedSubCategories));
          formData.append('mainCatagaryDescription', this.categoryDescription);

          if (this.image) { // Check for the single image
            const base64Image = await this.convertToBase64(this.image);
            formData.append('productImage', base64Image);
          }

          try {
            const response = await axios.post('/api/addCatagary', formData, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });

            if (response.status === 200) {
              this.showSnackbar('Category created successfully!', 'success');
              this.cancel(); // Reset form after successful creation
              this.centerLoading = false;
              this.clearForm();
            } else {
              this.showSnackbar('Unexpected response status.', 'warning');
              this.centerLoading = false;
            }
          } catch (error) {
            console.error('Error creating category:', error);
            this.showSnackbar('Failed to create category.', 'error');
            this.centerLoading = false;
          }
        },
        handleFiles(file) {
          const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

          if (!validImageTypes.includes(file.type)) {
            alert('Only image files are allowed!');
            return;
          }

          if (!(file instanceof File)) {
            console.error('Not a valid File object:', file);
            return;
          }

          this.image = file; // Store the single image
        },

        convertToBase64(file) {
          return new Promise((resolve, reject) => {
            if (file instanceof Blob) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = error => reject(error);
            } else {
              reject(new Error('Invalid file type'));
            }
          });
        },



        cancel() {
          // Reset form fields
          this.mainCategory = '';
          this.subCategories = [''];
          this.categoryDescription = '';
        },


      },
      mounted() {
        // Ensure userId and token are available in local storage
        if (!localStorage.getItem('userId') || !localStorage.getItem('token')) {
          this.showSnackbar('User not authenticated.', 'error');
          this.$router.push('/login');
        }
      },

    };
</script>

<style>
  .card-size{
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }
  .page-title {
    font-weight: bold;
  }
  .light-gray-text{
    color: #a09e9e !important;
  }
  .tool-bar-style {
    display: flex;
    justify-content: end;
  }
  .divider-vertical{
    color: #3c3b3b !important;
  }
  .gray-text {
    color: #757575 !important;
  }
  .tool-bar-style {
    display: flex;
    justify-content: end;
  }
  .product-details-card{
    height: 100%;
  }
  .card-two-title{
    color: black;
  }
  .card-hight-fixed{
    height: 100%;
    overflow-y: auto;
    max-height: 63vh;
  }
  .green-text {
    color: #057b0a;
    background-color: #83d187;
    padding-left: 0;
  }
  .red-text-btn {
    color: #b00d0d;
    background-color: #f0a5a5;
  }
  .divider-two-style{
    margin-top: -5px;
  }
  .margin-bottomn-fix{
    margin-top: 0px;
  }
  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  } 
  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 
  .custom-create-product-category {
    width: 300px;
    height: 300px;
    object-fit: contain;
    margin-left:auto;
    margin-right:auto;
  }

  .drop-area {

    padding: 20px;
    margin-top: 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    height: 150px; /* Adjust height as needed */
    background-color: #f0f0f0; /* Ash color */
    border: 2px dashed #ccc; /* Optional: dashed border for drag area */
    justify-content: center;
    align-items: center;
    text-align: center;

  }

  .centered-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hidden-file-input {
    display: none;
  }

  .image-card-container {
    margin: 0 8px 8px 0;
  }

  .image-card {
    width: 160px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }

  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 10px;

  }
  .placeholder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #888;
  }

  .remove-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: white;
    border-radius: 50%; /* This will make the background round */
    padding: 4px; /* Adjust as needed to control the icon size */
  }

  .remove-icon .v-icon {
    color: red;
  }


</style>